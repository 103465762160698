import { MainContainer, ChatContainer, MessageList, Message, MessageInput } from '@chatscope/chat-ui-kit-react';
import { ChevronLeftIcon, ChevronRightIcon, ChatBubbleIcon, Cross1Icon } from '@radix-ui/react-icons';
import { animated, useSpring } from '@react-spring/web';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './styles.css';
import { selectChatMessageModels, useSelector } from '~/app/selectors';
import { cn } from '~/utils/styles';
import { sendMessage } from '../actions';
// import profile from './profile.svg';

const Chat = () => {
    const dispatch = useDispatch();
    const chatMessages = useSelector(selectChatMessageModels);

    const onSendMessage = useCallback(
        (message: string) => {
            dispatch(sendMessage({ message: message }));
        },
        [dispatch],
    );

    const [isExpanded, setIsExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    // Replace direct window.innerWidth usage with safe default
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const chatPanelSpring = useSpring({
        width: isExpanded ? (windowWidth >= 1024 ? '50vw' : '80vw') : windowWidth >= 1024 ? '25vw' : '30vw',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateX(0%)' : 'translateX(100%)',
        config: { tension: 280, friction: 26 },
    });

    const chatBubbleSpring = useSpring({
        opacity: isVisible ? 0 : 1,
        transform: isVisible ? 'translateX(100%)' : 'translateX(0%)',
        config: { tension: 300, friction: 28 },
    });

    const toggleExpand = () => setIsExpanded(!isExpanded);
    const toggleVisibility = () => setIsVisible(!isVisible);

    if (!isVisible) {
        return (
            <animated.button
                onClick={toggleVisibility}
                style={chatBubbleSpring}
                className="pointer-events-auto fixed right-4 top-1/2 transform -translate-y-1/2 bg-primary-dark text-white rounded-full p-3 shadow-lg">
                <ChatBubbleIcon className="w-6 h-6" />
            </animated.button>
        );
    }

    return (
        <animated.div
            style={chatPanelSpring}
            className={cn(
                'bg-white/80 border-2 border-[#405820]/40 pointer-events-auto select-text top-[25%] bottom-0 h-[50%] right-0 absolute rounded-tl-lg rounded-bl-lg p-2',
            )}>
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 translate-x-[-50%] z-10">
                <button onClick={toggleExpand} className="bg-[#405820] text-white rounded-l-md p-2 mb-2 block">
                    {isExpanded ? <ChevronRightIcon className="w-4 h-4" /> : <ChevronLeftIcon className="w-4 h-4" />}
                </button>
                <button onClick={toggleVisibility} className="bg-[#405820] text-white rounded-l-md p-2 block">
                    <Cross1Icon className="w-4 h-4" />
                </button>
            </div>

            <MainContainer responsive className="!bg-transparent border-none">
                <ChatContainer className="!bg-transparent">
                    <MessageList className="!bg-transparent" autoScrollToBottom autoScrollToBottomOnMount>
                        {chatMessages.map(({ model, id }) => (
                            <Message key={id} model={model}>
                                <Message.Header sender={model.sender} sentTime={model.sentTime} />
                                {/* <Avatar name={model.sender} src={profile} /> */}
                            </Message>
                        ))}
                    </MessageList>

                    <MessageInput
                        placeholder="Type message here"
                        attachButton={false}
                        attachDisabled
                        fancyScroll
                        onSend={onSendMessage}
                        className="bg-transparent rounded-md"
                    />
                </ChatContainer>
            </MainContainer>
        </animated.div>
    );
};

export default Chat;
