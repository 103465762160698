import { onIdTokenChanged } from 'firebase/auth';
import { WritableDraft } from 'immer/dist/internal';
import React, { createContext, Dispatch, ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import { authCacheKey, axiosClient, queryClient, useLogin } from '~/apis';
import { useFirebaseAuth } from '~/apis/useFirebase';
import { config, localGameSnapshotKey, mockedAuthToken } from '~/app';

type AuthState = 'initializing' | 'idle' | 'login' | 'register';
type State = {
    token?: string;
    firebaseIdToken?: string;
    state: AuthState;
};
type Action =
    | { type: 'hydrate'; payload: State }
    | {
          type: 'commitCredentials';
          payload: { token: string; firebaseIdToken: string };
      }
    | { type: 'login' }
    | { type: 'register' }
    | { type: 'cancel' }
    | { type: 'logout' };
type ExternalState = State & { isAuthorized: boolean };

const AuthStateContext = createContext<ExternalState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

const authReducer = (draft: WritableDraft<State>, action: Action) => {
    switch (action.type) {
        case 'commitCredentials': {
            const { token, firebaseIdToken } = action.payload;
            draft.token = token;
            draft.firebaseIdToken = firebaseIdToken;
            break;
        }
        case 'login': {
            draft.state = 'login';
            break;
        }
        case 'register': {
            draft.state = 'register';
            break;
        }
        case 'cancel': {
            draft.state = 'idle';
            break;
        }
        case 'logout': {
            draft.state = 'idle';
            draft.token = undefined;
            draft.firebaseIdToken = undefined;
            break;
        }
        case 'hydrate': {
            const { state, firebaseIdToken, token } = action.payload;
            draft.state = state;
            draft.firebaseIdToken = firebaseIdToken;
            draft.token = token;
            break;
        }
    }
};

export interface AuthProviderProps {
    children: ReactNode;
}

const initializingState: State = { state: 'initializing' };

// instance for external access to apiToken e.g. from sagas
export const authStorage: {
    token?: string;
} = {};

const persistKey = 'auth';

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [state, dispatch] = useImmerReducer(authReducer, initializingState);

    const clearAuthData = useCallback(() => {
        dispatch({ type: 'logout' });
        localStorage.removeItem(authCacheKey);
        localStorage.removeItem(localGameSnapshotKey);
        queryClient.clear();
    }, [dispatch]);

    const restoreStatus = useRef({ storage: false, firebase: false });

    // hydrate initial state from local storage if available
    useEffect(() => {
        if (state.state !== 'initializing') return;

        // initially set up state (ssr compatible)
        const restoredState = JSON.parse(localStorage.getItem(persistKey) || '{}') as State;
        if (restoredState) {
            restoreStatus.current.storage = true;
            dispatch({ type: 'hydrate', payload: restoredState });
        } else {
            restoreStatus.current.storage = true;

            // firebase already tried to restore so we only waited for storage and can clear auth data
            if (restoreStatus.current.firebase) {
                console.log('cleared auth data');
                clearAuthData();
            } else {
                console.log('skipped clearing auth data, because firebase could potentially restore it');
            }
        }
    }, [clearAuthData, dispatch, state.state]);

    // sync every state change to local storage except for initial state
    useEffect(() => {
        // sync state changes to storage
        if (state.state === 'initializing') return;

        localStorage.setItem(persistKey, JSON.stringify(state));
    }, [state]);

    const externalState = useMemo<ExternalState>(
        () => ({ ...state, isAuthorized: state.token !== undefined && state.firebaseIdToken !== undefined }),
        [state],
    );

    const auth = useFirebaseAuth();
    const { mutateAsync: callLoginAsync } = useLogin(); // TODO: does this change?

    // immediately sync jwt bearer authorization token on state change with axios client
    // this is done on every render but a very cheap operation so this should be okay
    // if (axiosClient.defaults.headers.common === undefined) {
    //     // create axios header if it does not exist
    //     axiosClient.defaults.headers.
    // }
    if (config.useMockedAuthToken) {
        // set mocked jwt bearer authorization token
        axiosClient.defaults.headers.common.Authorization = `Bearer ${mockedAuthToken}`;
        authStorage.token = mockedAuthToken;
    } else if (state.token) {
        // set real jwt bearer authorization token
        axiosClient.defaults.headers.common.Authorization = `Bearer ${state.token}`;
        authStorage.token = state.token;
    }

    useEffect(() => {
        if (auth === undefined) return;
        const unsubscribe = onIdTokenChanged(auth, async (firebaseUser) => {
            if (!firebaseUser) {
                // initial trigger onIdTokenChanged with potentiallyunauthorized user
                if (!restoreStatus.current.firebase || !restoreStatus.current.storage) {
                    restoreStatus.current.firebase = true;

                    // firebase already tried to restore so we only waited for storage and can clear auth data
                    if (restoreStatus.current.storage) {
                        clearAuthData();
                    }
                }
                // firebase user was logged out so delete all associated data
                else {
                    clearAuthData();
                }
            } else {
                // use this callback only for firebase auth updates, not for initial login / registration
                // those are handled on the respective screens and this would otherwise lead to duplicated requests
                if (!state.token) return;

                // catch getIdToken error for unset users
                try {
                    const firebaseIdToken = await firebaseUser.getIdToken();

                    // check if firebaseIdToken is new
                    if (state.firebaseIdToken === firebaseIdToken) return;

                    // firebase user is authenticated, so request apiToken
                    try {
                        const tokenResponse = await callLoginAsync({ firebaseIdToken });
                        if (tokenResponse) {
                            dispatch({
                                type: 'commitCredentials',
                                payload: {
                                    token: tokenResponse.token,
                                    firebaseIdToken,
                                },
                            });
                        }
                    } catch (loginError) {
                        console.log('error login server after firebase token update', loginError);
                        clearAuthData();
                    }
                } catch (idTokenError) {
                    console.log('error reading refreshed firebase token', idTokenError);
                    clearAuthData();
                }
            }
        });
        return unsubscribe;
    }, [auth, clearAuthData, dispatch, callLoginAsync, state.firebaseIdToken, state.token]);

    return (
        <AuthStateContext.Provider value={externalState}>
            <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

export const useAuthState = (): ExternalState => {
    const context = useContext(AuthStateContext);
    if (context === undefined) throw new Error('useAuth must be used within an AuthProvider');
    return context;
};

export const useAuthDispatch = (): Dispatch<Action> => {
    const context = useContext(AuthDispatchContext);
    if (context === undefined) throw new Error('useAuth must be used within an AuthProvider');
    return context;
};
