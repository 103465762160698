/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { buffers, EventChannel, eventChannel, Task } from 'redux-saga';
import { call, cancel, flush, fork, put, takeLatest } from 'redux-saga/effects';
import { authStorage } from '~/auth';
import {
    sagaGameReceiver,
    sagaGameSender,
    setConnected,
    startDebug,
    StartDebugParameters,
    startFree,
    startTournament,
    startTraining,
    StartTrainingParameters,
} from '~/game';
import { GameType } from '~/server/src/dtos';
import { Controller, View } from '~/server/src/interfaces';
import {
    isRestoreGameParameters,
    rejoinGame,
    restoreGame,
    RestoreGameParameters,
    sagaRestoreGame,
    sagaSetupDebug,
    sagaSetupFree,
    sagaSetupTournament,
    sagaSetupTraining,
} from '~/setup';
import sagaRejoinGame from '~/setup/rejoin/sagaRejoinGame';
import { setServerTime, setUserId } from './connectionStore';
import ControllerLocalWrapper from './controllerLocalWrapper';

type ClientView = Pick<View, 'receiveMsg'>;

let ControllerRemote: any = undefined;

if (typeof window !== 'undefined') {
    ControllerRemote = require('~/server/src/tmcontrollerremote');
}

const controllerChannel = (onViewCreated: (view: ClientView) => void) => {
    return eventChannel((emitter) => {
        // handler for messages received by the controller which will be emitted into the saga context
        const view: ClientView = {
            receiveMsg: (state: number, params: unknown) => {
                emitter({ state, params });
            },
        };

        onViewCreated(view);

        return () => {
            console.log('remote controller event channel terminated');
            /* unsubscribe & cleanup */
        };
    }, buffers.expanding<any>(10)); // dynamic buffer which captures everything and prevents message loss
};

type MainSagaParams = void | StartTrainingParameters | RestoreGameParameters;

// https://gitlab.com/plusbyte/turfmaster/de.plusbyte.azaspiele.turfmaster.server/wikis/home
function* mainSaga(action: PayloadAction<MainSagaParams>) {
    console.log(`!!! main saga started with ${action.type} !!!`);

    // noop if cancel action is fired which already cancelled previous execution because of takeLatest usage
    if (action.type === cancelMainSaga.type) {
        console.log('mainSaga cancelled replaced with a noop and therefore cancelled if it was running before');
        return;
    }

    // create channel to listen to external controller events
    let view: ClientView | undefined = undefined;
    const channel: EventChannel<any> = yield call(controllerChannel, (v: ClientView) => {
        view = v;
    });

    // make sure channel is empty
    yield flush(channel);

    // view creation did not work so quit
    if (!view) {
        console.log('!!! main saga terminated because view creation failed !!!');
        return;
    }

    if (action.type === rejoinGame.type) {
        try {
            const rejoinLocalController = new ControllerLocalWrapper(GameType.Training);
            const rejoinRemoteController = new ControllerRemote.default(`${process.env.GATSBY_API}`, authStorage.token);
            yield call(sagaRejoinGame, view, rejoinLocalController, rejoinRemoteController, channel);

            // @ts-ignore TODO: fix
            rejoinLocalController.closeConnection?.();
            rejoinRemoteController.closeConnection?.();
        } finally {
            console.log('!!! main saga terminated !!!');
            channel.close();
        }
        return;
    }

    // new controller instance for each game start
    let controller: Controller | null;

    // offline game start training or restore training
    if (
        action.type === startTraining.type ||
        (action.type === restoreGame.type &&
            isRestoreGameParameters(action.payload) &&
            action.payload.mode === 'training')
    ) {
        console.log('mainSaga', 'using local controller wrapper in training mode');
        controller = new ControllerLocalWrapper(GameType.Training);
    }
    // online game
    else {
        if (!authStorage.token) {
            console.log('mainSaga', 'game could not be started due to missing authorization token');
            return;
        }

        console.log('mainSaga', 'using remote controller for api url', process.env.GATSBY_API);
        const controllerRemote: typeof ControllerRemote = new ControllerRemote.default(
            `${process.env.GATSBY_API}`,
            authStorage.token,
        );

        // promise gets resolved only after controller is fully connected
        // safe-guard so that we do not set userId and serverTime before they are available
        // and prevents premature navigation to game screen
        yield call([controllerRemote, controllerRemote.waitForConnection]);
        console.log('mainSaga', 'Remote controller connection established');

        yield put(setUserId(controllerRemote.userId));
        yield put(setServerTime(controllerRemote.serverTime));

        controller = controllerRemote;
    }

    // send actions from user interface as state-messages to controller
    // @ts-ignore TODO: fix
    const sender: Task = yield fork(sagaGameSender, controller);

    try {
        // training startup with NEWGAME event. returns instant after starting the game
        if (action.type === startTraining.type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield call(sagaSetupTraining, view, controller, action.payload as StartTrainingParameters, channel);
        } else if (action.type === restoreGame.type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!(yield call(sagaRestoreGame, view, controller, channel)) as boolean) return;
        } else if (action.type === startFree.type) {
            // free startup with GETGAMES, NEWGAME / JOINGAME. therefore a long running task with lobby handling etc.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!(yield call(sagaSetupFree, view, controller, channel)) as boolean) return;
        } else if (action.type === startTournament.type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield call(sagaSetupTournament, view, controller, channel);
        } else if (action.type === startDebug.type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield call(sagaSetupDebug, view, controller, channel, action.payload as StartDebugParameters);
        }

        // listen and react to controller actions in an endless loop
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield call(sagaGameReceiver, controller, channel);
    } finally {
        console.log('!!! main saga terminated !!!');

        channel.close();
        // @ts-ignore TODO: fix
        controller.closeConnection?.();
        yield cancel(sender);

        yield put(setConnected(false));
    }
}

export const cancelMainSaga = createAction('cancelMainSaga');

function* mainSagaRoot() {
    yield takeLatest(
        [
            startTraining.type,
            startFree.type,
            restoreGame.type,
            rejoinGame.type,
            startTournament.type,
            startDebug.type,
            cancelMainSaga.type,
        ],
        mainSaga,
    );
}

export default mainSagaRoot;
