import { ChatMessage } from './dtos';
import LocalController from './tmcontrollerlocal';
import { Board, Game } from './tmmodel';

export enum ControllerType {
    LOCAL = 'local',
    REMOTE = 'remote',
}

export interface Controller {
    receiveMsg: (state: number, params: unknown) => false | void;
    sendMsg: (state: number, params: unknown) => false | void;
    getGame(): Game;
    getBoard(): Board;
    getChatMessages: () => ChatMessage[];
    getPlayerId: () => number | false;
    getHorseId: () => number | false;
    closeConnection?: () => void;
    getType: () => ControllerType;
}

export interface View {
    receiveMsg: (state: number, params: unknown) => void;
    sendMsg: (state: number, params: unknown) => false | void;
    controller: LocalController;
    playerid: number;
}
