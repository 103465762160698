import { Horse, Player } from '../tmmodel';

export enum GameType {
    Training = 'training',
    FreeBronze = 'freebronze',
    FreeSilver = 'freesilver',
    FreeGold = 'freegold',
    TournamentBronze = 'tournamentbronze',
    TournamentSilver = 'tournamentsilver',
    TournamentGold = 'tournamentgold',
}

export enum GameStatus {
    Waiting = 'waiting',
    Running = 'running',
    Finished = 'finished',
    Cancelled = 'cancelled',
}

export const isBronzeGameType = (gameType: GameType): boolean => {
    return gameType === GameType.FreeBronze || gameType === GameType.TournamentBronze;
};

export const isSilverGameType = (gameType: GameType): boolean => {
    return gameType === GameType.FreeSilver || gameType === GameType.TournamentSilver;
};

export const isGoldGameType = (gameType: GameType): boolean => {
    return gameType === GameType.FreeGold || gameType === GameType.TournamentGold;
};

export const isFreeGameType = (gameType: GameType): boolean => {
    return gameType === GameType.FreeBronze || gameType === GameType.FreeSilver || gameType === GameType.FreeGold;
};

export const isTournamentGameType = (gameType: GameType): boolean => {
    return (
        gameType === GameType.TournamentBronze ||
        gameType === GameType.TournamentSilver ||
        gameType === GameType.TournamentGold
    );
};

// check if game type is allowed with basic subscription
export const isGameTypeAllowedBasic = (gameType: GameType): boolean => {
    // only training mode is allowed without a premium subscription
    return gameType === GameType.Training;
};

// used by getgames
export interface GameOverview {
    gameId: string;
    timeCreated: string; // timestamp when this game was created (ISO 8601: YYYY-MM-DDTHH:mm:ss.sssZ)
    name: string;
    gameType: GameType;
    horseamount: number;
    players: Player[];
    horses: Horse[];
}

export interface ChatMessage {
    userId: string;
    timestamp: string; // timestamp when this message was sent (ISO 8601: YYYY-MM-DDTHH:mm:ss.sssZ)
    message: string;
}
export interface GameState {
    gameId: string;
    timeCreated: string; // timestamp when this game was created (ISO 8601: YYYY-MM-DDTHH:mm:ss.sssZ)
    gameType: GameType;
    boardId: number;
    status: GameStatus;
    chatMessages: ChatMessage[];
    lastRemovedCards: number[];
    state: any;
    statePrev?: any;
    statePrevPrev?: any;
}

export interface GameSnapshot {
    snapshotId: string;
    timestamp: string;
    userId: string;
    message: string;
    userAgent: string;
    gameState: GameState;
}

export interface GameSnapshotRequest {
    message?: string;
    userAgent?: string;
    gameState: GameState;
}
export interface GameCreateSnapshotRequest {
    message?: string;
    userAgent?: string;
}
