import { cloneDeep } from 'lodash';
import { GameState, GameStatus, GameType } from '~/server/src/dtos';
import { Controller, View } from '~/server/src/interfaces';
import Computer from '~/server/src/tmcomputer';
import ControllerLocal from '~/server/src/tmcontrollerlocal';
import Turfmaster, { Board, Game } from '~/server/src/tmmodel';

export const localGameSnapshotKey = 'localGameSnapshot';

const playerId = 0;

export const loadLocalGameSnapshot = (): GameState | null => {
    const localGameSnaphotString = localStorage.getItem(localGameSnapshotKey);
    if (localGameSnaphotString) {
        try {
            return JSON.parse(localGameSnaphotString);
        } catch (e) {
            console.log('error while loading persisted game state', e);
            return null;
        }
    }

    // fallback solution, return null instead of throwing an exception
    return null;
};

class ClientControllerLocal extends ControllerLocal implements Controller {
    public constructor(mode: GameType = GameType.Training) {
        super(mode);
    }

    /**
     * Receive an event from client and handle it. Restore / Rejoin events are handled here,
     * the rest is handled by the localController.
     *
     * @param state new game state
     * @param params params of new game state
     */
    public receiveMsg = (state: number, params: unknown): false | void => {
        switch (state) {
            case Turfmaster.stateMachine.RESTOREGAME:
                const foundSnapshot = loadLocalGameSnapshot();

                // send rehydrated game to client to indicate game would be restorable
                // send null if not, client knows how to handle this
                if (foundSnapshot) console.log('SNAPSHOT FOUND', foundSnapshot);
                else console.log('SNAPSHOT NOT FOUND', foundSnapshot);

                const view = (params as { view: View }).view;
                if (view) {
                    return view.receiveMsg(Turfmaster.stateMachine.RESTOREGAME, {
                        game: cloneDeep(foundSnapshot),
                    });
                }
                break;
            case Turfmaster.stateMachine.REJOINGAME:
                const snapshot = loadLocalGameSnapshot();

                if (snapshot) {
                    this.gameid = snapshot.gameId;
                    this.board = new Board(snapshot.boardId);
                    this.gameType = snapshot.gameType;
                    this.game = snapshot.state;
                    this.lastRemovedCards = snapshot.lastRemovedCards;

                    const playerView = (params as { view: View }).view;
                    this.playerViews = [playerView];

                    const computerViews: Computer[] = [];
                    for (let i = 1; i < this.game.players.length; i++) {
                        // TODO: fix type of computer state
                        const computerState = this.game.players[i] as unknown as View & { strength: number };
                        const computer = new Computer(i, computerState.strength, this);
                        this.playerViews[i] = computer;
                        computerViews.push(computer);
                    }

                    // confirm rejoin and send playerid to player which is always 0 in training mode
                    // because everything is local and this is hardcoded in sagaSetupTraining
                    // all other players are bots
                    playerView.receiveMsg(Turfmaster.stateMachine.REJOINGAME, { playerid: playerId });

                    computerViews.forEach((computerView) => {
                        if (
                            this.game.currentState === Turfmaster.stateMachine.SELECTMOVE ||
                            this.game.currentState === Turfmaster.stateMachine.SELECTMOVE
                        ) {
                            // if controller waits in state SELECTMOVE or SELECTCARDDICE: resend SELECTCARDDICE to all computer bots
                            computerView.receiveMsg(Turfmaster.stateMachine.SELECTCARDDICE, {});
                        } else {
                            computerView.receiveMsg(this.game.currentState, {});
                        }
                    });

                    console.log('SNAPSHOT RESTORED', snapshot);
                } else {
                    console.log('SNAPSHOT RESTORE ERROR', 'not found');
                }

                break;
            default:
                return super.receiveMsg(state, cloneDeep(params));
        }
    };

    /**
     * Take message that will be send to the client by the localcontroller,
     * persist the current game state and then let it continue to the client.
     *
     * TODO: delete persisted game state, if game is finished
     *
     * @param state new game state
     * @param params params of new game state
     */
    public sendMsg = (state: number, params: unknown): false | void => {
        const sendMsgResult = super.sendMsg(state, cloneDeep(params));

        const gameState = super.getGameSnapshot();
        const oldLocalGameSnapshot = loadLocalGameSnapshot();

        // ENDROUND is a temporary state that can't be restored
        if (gameState && gameState.currentState !== Turfmaster.stateMachine.ENDROUND) {
            const localGameSnapshot: GameState = {
                gameId: super.gameid,
                timeCreated: super.timecreated,
                gameType: super.gameType,
                boardId: gameState.boardid,
                status: GameStatus.Running,
                chatMessages: super.getChatMessages(),
                lastRemovedCards: super.lastRemovedCards,
                state: gameState,
                statePrev: oldLocalGameSnapshot?.state,
                statePrevPrev: oldLocalGameSnapshot?.statePrev,
            };
            localStorage.setItem(localGameSnapshotKey, JSON.stringify(localGameSnapshot));
            console.log('SNAPSHOT SAVED', localGameSnapshot);
        } else {
            console.log('SNAPSHOT ERROR', 'controller did not return a snapshot');
        }

        return sendMsgResult;
    };

    public getGame = (): Game => {
        return cloneDeep(super.getGame());
    };

    public getBoard = (): Board => {
        return cloneDeep(super.getBoard());
    };
}

export default ClientControllerLocal;
