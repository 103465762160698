// extracted by mini-css-extract-plugin
export var AcceptButtonDisabled = "l_P";
export var AcceptButtonEnabled = "l_Q";
export var Dice = "l_G";
export var DicePulsating = "l_M";
export var DiceSelectable = "l_H";
export var DiceSelected = "l_J";
export var DiceShuffling = "l_K";
export var Move = "l_S";
export var barRightAcceptButtonBounceIn = "l_R";
export var barRightMoveBounceIn = "l_T";
export var dicePulseAnimation = "l_N";
export var diceShuffleAnimation = "l_L";