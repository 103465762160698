import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

export interface ConnectionState {
    readonly userId: string | null;
    readonly serverTime: string | null;
}

export const initialConnectionState: ConnectionState = {
    userId: null,
    serverTime: null,
};

export const setUserId = createAction<string>('SET_USER_ID');
export const setServerTime = createAction<string>('SET_SERVER_TIME');

export const connectionReducer = createReducer(initialConnectionState, {
    [setUserId.type]: (state, action: PayloadAction<string>) => {
        state.userId = action.payload;
    },
    [setServerTime.type]: (state, action: PayloadAction<string>) => {
        state.serverTime = action.payload;
    },
});
