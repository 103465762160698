import { LeagueType } from '.';

export interface Toplist {
    toplistLeague: LeagueType;
    intervalStart: string; // timestamp this interval starts (ISO 8601: YYYY-MM-DDTHH:mm:ss.sssZ)
    intervalEnd: string; // timestamp this interval ends (ISO 8601: YYYY-MM-DDTHH:mm:ss.sssZ)
    status: ToplistStatus;
    finalSnapshot: ToplistUser[];
}

export interface ToplistUser {
    userId: string;
    toplistPoints: number;
    toplistRank: number;
    toplistPromotion: number;
}

export enum ToplistStatus {
    Running = 'running',
    Archived = 'archived',
}
