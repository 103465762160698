/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Modal, Slide } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import React, { memo, useCallback, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { useAccount } from '~/apis';
import { HorseStatistic, rounds, selectHorsesStatistics, useSelector, useTranslation } from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { useOnStateChange } from '~/utils';
import { cn } from '~/utils/styles';
import HorseCell, { HorseCellProps } from './HorseCell';
import UserCell, { UserCellProps } from './UserCell';

export type StatisticsModalState = 'closed' | 'timed' | 'open';

interface StatisticsProps {
    state: StatisticsModalState;
    onClose: () => void;
    duration?: number;
}

export type StatisticsColumn = Column<HorseStatistic> & {
    collapse?: boolean;
};

const Statistics = ({ state, onClose, duration = Turfmaster.endRoundTimeout }: StatisticsProps) => {
    const { t } = useTranslation();
    const { data: account } = useAccount();
    const statistics = useSelector(selectHorsesStatistics);

    const [{ width }] = useSpring<{ width: string }>(
        {
            from: { width: '100%' },
            to: { width: state === 'timed' ? '0%' : '100%' },
            config: { duration: state === 'timed' ? duration : 0 },
        },
        [state, duration],
    );

    const statisticsColumns = useMemo<StatisticsColumn[]>(
        () => [
            {
                id: 'rank',
                Header: '#',
                accessor: (row) => (row.totalRank < 0 ? '-' : row.totalRank + 1),
                collapse: true,
            },
            {
                id: 'horse',
                Header: t('game_statisticsHeaderHorse'),
                accessor: (row): HorseCellProps => ({ color: row.color, name: row.name, jockeyName: row.jockeyName }),
                Cell: HorseCell,
            },
            {
                id: 'user',
                Header: t('game_statisticsHeaderPlayer'),
                accessor: (row): UserCellProps => ({ country: row.country, name: row.playerName }),
                Cell: UserCell,
            },
            ...rounds.map<StatisticsColumn>((round) => ({
                id: `${round}`,
                Header: `${round + 1}`,
                accessor: (row) => (row.points[round] > 0 ? row.points[round] : '-'),
                collapse: true,
            })),
            { Header: () => <span>&#x2211;</span>, accessor: 'pointsSum', collapse: true },
        ],
        [t],
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<HorseStatistic>({
        columns: statisticsColumns,
        data: statistics,
    });

    useOnStateChange(
        state,
        useCallback(
            (newState) => {
                let timeout: number | undefined;
                if (newState === 'timed') {
                    timeout = window.setTimeout(onClose, duration);
                }
                return () => window.clearTimeout(timeout);
            },
            [duration, onClose],
        ),
    );

    return (
        <Modal disablePortal open={state !== 'closed'} onClose={onClose} style={{ pointerEvents: 'all' }}>
            <Slide direction="up" in={state !== 'closed'}>
                <div className="focus:outline-none flex flex-col items-stretch justify-center w-full h-full p-4 pointer-events-none">
                    <div className="bg-gray-50 relative w-full max-w-4xl mx-auto overflow-auto rounded-md shadow-lg pointer-events-auto">
                        <div className="md:p-6 flex flex-col items-stretch p-4">
                            <div className="flex flex-col space-y-2">
                                <h2>
                                    <strong>{t('game_statisticsTitle')}</strong>
                                </h2>

                                <table {...getTableProps()} className="w-full overflow-y-auto border-collapse">
                                    <thead className="border-b border-gray-400">
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column, index, allColumns) => (
                                                    <th
                                                        {...column.getHeaderProps({
                                                            className: cn('p-1 md:p-2 text-left', {
                                                                'text-center': index === 0,
                                                                'text-right': index === allColumns.length - 1,
                                                            }),
                                                            style: {
                                                                width: (column as StatisticsColumn).collapse
                                                                    ? '0.0000000001%'
                                                                    : '1%',
                                                            },
                                                        })}>
                                                        {column.render('Header')}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody
                                        {...getTableBodyProps()}
                                        className="md:text-base lg:text-lg text-sm divide-y">
                                        {rows.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    className={cn({
                                                        'bg-secondary bg-opacity-75':
                                                            row.original.userId === account?.userId,
                                                    })}
                                                    {...row.getRowProps()}>
                                                    {row.cells.map((cell, index, cells) => (
                                                        <td
                                                            {...cell.getCellProps({
                                                                className: cn('px-2 md:px-3 py-1 md:py-2', {
                                                                    // align last column with points to the right
                                                                    'text-right': index === cells.length - 1,
                                                                }),
                                                                style: {
                                                                    width: (cell.column as StatisticsColumn).collapse
                                                                        ? '0.0000000001%'
                                                                        : '1%',
                                                                },
                                                            })}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {state === 'timed' && (
                            <animated.div
                                className="top-0 absolute w-full h-1.5 rounded-md bg-primary"
                                style={{ width }}
                            />
                        )}

                        <button
                            className="top-2 right-2 md:w-8 md:h-8 md:p-2 absolute flex items-center justify-center w-6 h-6 p-1 bg-gray-200 border border-gray-400 rounded-full shadow-xl"
                            onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-full h-full">
                                <path
                                    d="M.75 23.249l22.5-22.5m0 22.5L.75.749"
                                    stroke="#000"
                                    fill="none"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};

export default memo(Statistics);
