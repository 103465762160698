import { MessageModel } from '@chatscope/chat-ui-kit-react';
import { orderBy, sortBy } from 'lodash';
import { createCachedSelector, createStructuredCachedSelector } from 're-reselect';
import { TypedUseSelectorHook, useSelector as untypedUseSelector } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { Horse } from '~/board';
import { debugHorseData } from '~/end/shared';
import { CardValue } from '~/overlay';
import Turfmaster from '~/server/src/tmmodel';
import { Position } from './models';
import { State } from './store';

export const useSelector: TypedUseSelectorHook<State> = untypedUseSelector;

/* Connection selectors */
export const selectUserId = (state: State) => state.connection.userId;
export const selectServerTime = (state: State) => state.connection.serverTime;

/* Setup selectors */
export const selectGameId = (state: State) => state.setup.gameId;
export const selectGameName = (state: State) => state.setup.gameName;
export const selectGameType = (state: State) => state.setup.gameType;
export const selectGameStartDate = (state: State) => state.setup.gameStartDate;
export const selectSetupGames = (state: State) => state.setup.games;
export const selectSetupGamesSorted = createCachedSelector(selectSetupGames, (games) =>
    orderBy(games, (game) => new Date(game.timeCreated).getTime(), 'desc'),
)(() => 'gamesSorted');
export const selectSetupTournaments = (state: State) => state.setup.tournaments;
export const selectHorseAmount = (state: State) => state.setup.horseAmount;
export const selectIsNewGame = (state: State) => state.setup.isNewGame;
export const selectRestoreState = (state: State) => state.setup.restoreState;
export const selectRejoinState = (state: State) => state.setup.rejoinState;

/* Board selectors */
export const selectHurdles = (state: State) => state.board.hurdles;
export const selectMoves = (state: State) => state.board.moves;
export const selectHorses = (state: State) => state.board.horses;
export const selectHorse = (state: State, index: number): Horse | undefined => state.board.horses[index];
export const selectHorsesCount = (state: State) => state.board.horses.length;
export const selectHorsesField = (state: State) => state.board.horseFields;
export const selectHorseField = (state: State, index: number) => state.board.horseFields[index];
export const selectCameraPosition = (state: State) => state.board.camera as Position;

/* Game selectors */
export const selectConnected = (state: State) => state.game.connected;
export const selectConnectionTimeout = (state: State) => state.game.connectionTimeout;
export const selectCurrentState = (state: State) => state.game.currentState;
export const selectRound = (state: State) => state.game.round;

export const selectTurnPos = (state: State) => state.game.turnpos;
export const selectTurnOrder = (state: State) => state.game.turnorder;
export const selectTurnDices = (state: State) => state.game.turndices;
export const selectTurnDicesCur = (state: State) => state.game.turndicescur;
export const selectTurnType = (state: State) => state.game.turntype;
export const selectTurnDicesPlayerId = (state: State) => state.game.turndiceplayerid;

export const selectPlayerId = (state: State) => state.setup.playerId;
export const selectPlayers = (state: State) => state.game.players;
export const selectPlayer = (state: State, index: number) => state.game.players[index];
export const selectPlayerName = (state: State, index: number) =>
    state.game.players[index] ? state.game.players[index].name : '';
export const selectPlayerCountry = (state: State, index: number) =>
    state.game.players[index] ? state.game.players[index].country : undefined;

export const selectHorsesRanks = (state: State) => state.game.horsesRanks;
export const selectHorseRanks = (state: State, index: number) => state.game.horsesRanks[index];
export const selectHorsesHandi = (state: State) => state.game.horsesHandi;
export const selectHorseHandi = (state: State, index: number) => state.game.horsesHandi[index];
export const selectHorsesPoints = (state: State) => state.game.horsesPoints;
export const selectHorsePoints = (state: State, index: number) => state.game.horsesPoints[index];
export const selectHorsesFinished = (state: State) => state.game.horsesFinished;
export const selectHorseFinished = (state: State, index: number) => state.game.horsesFinished[index];
export const selectHorsesDropped = (state: State) => state.game.horsesDropped;
export const selectHorseDropped = (state: State, index: number) => state.game.horsesDropped[index];
export const selectHorsesStarted = (state: State) => state.game.horsesStarted;
export const selectHorsesBehindGoal = (state: State) => state.game.horsesBehindGoal;

export const preGameStates = [
    Turfmaster.stateMachine.GETGAMES,
    Turfmaster.stateMachine.NEWGAME,
    Turfmaster.stateMachine.RESTOREGAME,
    Turfmaster.stateMachine.JOINGAME,
    Turfmaster.stateMachine.JOINGAMEOPP,
    Turfmaster.stateMachine.GETTOURNAMENTS,
    Turfmaster.stateMachine.JOINTOURNAMENT,
];
export const gameStates = Object.values(Turfmaster.stateMachine).filter((state) => !preGameStates.includes(state));
export const selectIsGameRunning = (state: State) => !preGameStates.includes(state.game.currentState);

/* Overlay selectors */
export const selectHandiFailureHorseIndex = (state: State) => state.overlay.handiFailureHorseIndex;
export const selectCardDice = (state: State) => state.overlay.cardDice;
export const selectLastCardHorseIndex = (state: State) => state.overlay.lastCardHorseIndex;
export const selectLastCard = (state: State) => state.overlay.lastCard;
export const selectLastTurnDicePlayerId = (state: State) => state.overlay.lastTurnDicePlayerId;
export const selectLastTurnDices = (state: State) => state.overlay.lastTurnDices;
export const selectLastTurnDicesCur = (state: State) => state.overlay.lastTurnDicesCur;

export const selectHorsesCards = (state: State) => state.overlay.horsesCards;
export const selectHorseCards = (state: State, horseIndex: number) =>
    state.overlay.horsesCards[horseIndex] as CardValue[][] | undefined;
export const selectHorsesBonuscards = (state: State) => state.overlay.horsesBonusCards;
export const selectHorseBonuscards = (state: State, horseIndex: number) => state.overlay.horsesBonusCards[horseIndex];
export const selectOwnHorseRemovedCards = (state: State) => state.overlay.ownHorseRemovedCards as CardValue[];

export const selectSelectedDicesLocal = (state: State) => state.overlay.selectedDicesLocal;
export const selectSelectedCardIndexLocal = (state: State) => state.overlay.selectedCardIndexLocal;

export const selectChatMessages = (state: State) => state.overlay.chatMessages;

export const selectChatMessageModels = createSelector(
    selectChatMessages,
    selectUserId,
    selectPlayers,
    (chatMessages, userId, players) =>
        sortBy(chatMessages, (message) => new Date(message.timestamp).getTime(), 'asc').map(
            (message, index, messages) => {
                const prevMessage = index > 0 ? messages[index - 1] : null;
                const nextMessage = index < messages.length - 1 ? messages[index + 1] : null;

                let position: 'first' | 'normal' | 'last' | 'single';

                if (!prevMessage || prevMessage.userId !== message.userId) {
                    position = nextMessage && nextMessage.userId === message.userId ? 'first' : 'single';
                } else if (!nextMessage || nextMessage.userId !== message.userId) {
                    position = 'last';
                } else {
                    position = 'normal';
                }

                const player = players.find((player) => player.userId === message.userId);
                const model: MessageModel = {
                    message: message.message,
                    sentTime: new Date(message.timestamp).toLocaleTimeString(),
                    position: position,
                    direction: message.userId === userId ? 'outgoing' : 'incoming',
                    sender: player?.name ?? message.userId,
                };

                const id = `${message.userId}-${message.timestamp}`;

                return { model, id, ...message };
            },
        ),
);

export const selectChatMessagesCount = createSelector(selectChatMessages, (chatMessages) => chatMessages.length);

export const selectChatMessagesSorted = createSelector(selectChatMessages, (chatMessages) =>
    sortBy(chatMessages, (message) => new Date(message.timestamp).getTime()),
);

/**
 * Returns the rank of the specified horse but takes into consideration,
 * that before any horse was moved all horses have the same rank.
 */
export const selectHorseRanksCorrected = createCachedSelector(
    selectHorsesField,
    selectHorsesHandi,
    selectHorsesRanks,
    (_state: State, horseIndex: number) => horseIndex,
    (horsesField, horsesHandi, horsesRanks, horseIndex) => {
        const ranks = horsesRanks[horseIndex];
        if (horsesField.some((field) => field.pos > 0)) {
            return ranks ?? { rank: -1, totalRank: -1 };
        } else {
            return { rank: horsesHandi[horseIndex] ?? -1, totalRank: ranks?.totalRank ?? -1 };
        }
    },
)((_state, horseIndex) => horseIndex);

export interface HorseStatistic {
    name: string;
    color: number;
    points: number[];
    jockeyName: string;
    pointsSum: number;
    turnPos: number;
    totalRank: number;
    userId: string;
    playerName: string;
    country: string;
}

export interface PlayerStatistic {
    index: number;
    name: string;
    points: number[];
    pointsSum: number;
    totalRank: number;
}

export const rounds = [...Array(Turfmaster.rounds).keys()];
export const selectHorsesStatistics = createSelector(
    selectRound,
    selectTurnOrder,
    selectPlayers,
    selectHorses,
    selectHorsesPoints,
    selectHorsesRanks,
    (round, turnOrder, players, horses, horsesPoints, horsesRanks): HorseStatistic[] =>
        horses && horses.length > 0
            ? sortBy(
                  horses.map<HorseStatistic>(({ color, name, jockeyName, playerId }, index) => ({
                      name,
                      color,
                      jockeyName,
                      points: rounds.map((round) => horsesPoints?.[index]?.[round] ?? 0),
                      pointsSum: horsesPoints?.[index]?.reduce((prev, cur) => prev + cur, 0) ?? 0,
                      totalRank: round > 0 ? horsesRanks?.[index]?.totalRank ?? 0 : -1,
                      turnPos: turnOrder[index],
                      userId: players?.[playerId]?.userId,
                      playerName: players?.[playerId]?.name,
                      country: players?.[playerId]?.country,
                  })),
                  (item) => (round > 0 ? item.totalRank : item.turnPos),
              )
            : debugHorseData,
);

export const selectLastRoundFinished = createSelector(
    selectRound,
    selectHorsesFinished,
    selectCurrentState,
    (round, horsesFinished, currentState) =>
        round >= Turfmaster.rounds - 1 &&
        (currentState === Turfmaster.stateMachine.ENDROUND ||
            currentState === Turfmaster.stateMachine.ENDGAME ||
            horsesFinished.every((horseFinished) => horseFinished)),
);

export const selectPlayersStatistics = createSelector(
    selectPlayers,
    selectHorses,
    selectHorsesPoints,
    (players, horses, horsesPoints): PlayerStatistic[] =>
        sortBy(
            players?.map(({ id, name, totalRank }, index) => {
                const playerHorses = horses.filter(({ playerId }) => playerId === id);

                return {
                    index,
                    name,
                    points: rounds.map((round) =>
                        playerHorses.reduce((prev, cur) => prev + horsesPoints?.[cur.index]?.[round] ?? 0, 0),
                    ),
                    pointsSum: playerHorses.reduce(
                        (prev, cur) => prev + horsesPoints?.[cur.index]?.reduce((prev, cur) => prev + cur, 0) ?? 0,
                        0,
                    ),
                    totalRank,
                };
            }),
            (item) => item.totalRank,
        ),
);

export const selectCurrentHorseIndex = createSelector(
    selectTurnOrder,
    selectTurnPos,
    (turnOrder, turnPos) => turnOrder[turnPos],
);

export const selectCurrentHorse = createSelector(
    selectCurrentHorseIndex,
    selectHorses,
    (currentHorseIndex, horses) => horses[currentHorseIndex],
);

export const selectIsCurrentOwnHorse = createSelector(
    selectPlayerId,
    selectCurrentHorse,
    (playerId, currentHorse) => currentHorse?.playerId === playerId,
);

export const selectOwnHorses = createSelector(selectHorses, selectPlayerId, (horses, playerId) =>
    horses.filter((horse) => horse.playerId === playerId),
);

export const selectIsOwnHorseIndexCurrent = createCachedSelector(
    selectOwnHorses,
    selectCurrentHorseIndex,
    (_state: State, horseIndex: number) => horseIndex,
    (ownHorses, currentHorseIndex, ownHorseIndex) => ownHorses[ownHorseIndex]?.index === currentHorseIndex,
)((_state, horseIndex) => horseIndex);

export const selectIsOwnHorseIndex = createCachedSelector(
    selectPlayerId,
    selectHorses,
    (_state: State, horseIndex: number) => horseIndex,
    (playerId, horses, horseIndex) => horses[horseIndex]?.playerId === playerId,
)((_state, horseIndex) => horseIndex);

export const selectCurrentHorseCards = createSelector(
    selectCurrentHorseIndex,
    selectHorsesCards,
    (currentHorseIndex, horsesCards) => horsesCards[currentHorseIndex] as CardValue[][] | undefined,
);

export const selectCurrentHorseBonuscards = createSelector(
    selectCurrentHorseIndex,
    selectHorsesBonuscards,
    (currentHorseIndex, horsesBonuscards) => horsesBonuscards[currentHorseIndex],
);

export const selectHorseCardsCurrentCount = createCachedSelector(
    selectRound,
    selectHorseCards,
    (round, horseCards) => horseCards?.[round]?.length ?? 0,
)((_state, horseIndex) => horseIndex);

export const selectHorseCardDeck = createStructuredCachedSelector({
    cards: selectHorseCards,
    bonuscards: selectHorseBonuscards,
})((_state, horseIndex) => horseIndex);

export const selectHorseHasBonuscards = createCachedSelector(
    selectHorseBonuscards,
    selectHorseCards,
    (bonusCards, horseCards) => bonusCards === -1 && (horseCards?.[Turfmaster.rounds]?.length ?? 0) > 0,
)((_state, horseIndex) => horseIndex);

export const selectHorseCardDeckCurrentMeta = createStructuredCachedSelector({
    cardsCount: selectHorseCardsCurrentCount,
    hasBonuscards: selectHorseHasBonuscards,
})((_state, horseIndex) => horseIndex);

export const selectCurrentHorseCardDeck = createStructuredSelector<
    State,
    { cards?: CardValue[][]; bonuscards: number }
>({
    cards: selectCurrentHorseCards,
    bonuscards: selectCurrentHorseBonuscards,
});

export const selectHorseHighlightAllowed = createSelector(
    selectCurrentState,
    selectRound,
    (currentState: number, currentRound: number) =>
        // prevent horse blinking while last turn in round is shown with a delay
        currentState !== Turfmaster.stateMachine.SELECTMOVEOPP &&
        // prevent horse blinking if user should select dice which does not address a specific horse
        currentState !== Turfmaster.stateMachine.SELECTDICES &&
        // prevent horse blinking before first and after last round
        currentRound >= 0 &&
        currentRound < Turfmaster.rounds,
);

export const selectIsOwnHighlightedHorse = createCachedSelector(
    selectHorseHighlightAllowed,
    selectCurrentHorseIndex,
    selectPlayerId,
    selectHorses,
    (_state: State, horseIndex: number) => horseIndex,
    (isHighlightAllowed, currentHorseIndex, playerId, horses, horseIndex) =>
        isHighlightAllowed && horses[horseIndex]?.playerId === playerId && currentHorseIndex === horseIndex,
)((_state, horseIndex) => horseIndex);

export const selectOwnHorse = createCachedSelector(
    selectHorses,
    selectPlayerId,
    (_state: State, ownHorseIndex: number) => ownHorseIndex,
    (horses, playerId, ownHorseIndex) => horses.filter((horse) => horse.playerId === playerId)[ownHorseIndex],
)((_state, ownHorseIndex) => ownHorseIndex);

export const selectCurrentRoundWinnerHorse = createSelector(selectRound, selectHorsesPoints, (round, horsesPoints) => {
    // check if horse has the points a winner should have in this round
    for (const [horseIndex, horsePoints] of horsesPoints.entries())
        if (horsePoints[round] === Turfmaster.points[0]) return horseIndex;
    return -1;
});

export const selectActiveHorsesField = createSelector(
    selectHorsesField,
    selectHorsesFinished,
    selectHorsesDropped,
    (horsesField, horsesFinished, horsesDropped) => {
        return (horsesField || []).filter((_value, index) => !horsesFinished[index] && !horsesDropped[index]);
    },
);
