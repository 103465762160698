/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectCurrentHorse,
    selectCurrentState,
    selectHorses,
    selectOwnHorses,
    selectRound,
    selectTurnDicesPlayerId,
} from '~/app';
import HorseIcon from '~/components/HorseIcon';
import Turfmaster from '~/server/src/tmmodel';
import { useKeyPress } from '~/utils';
import { cn } from '~/utils/styles';

export interface HorseSelectionProps {
    selectedOwnHorse: number;
    onOwnHorseSelected: (ownHorse: number) => void;
}

const HorseSelection = ({ onOwnHorseSelected, selectedOwnHorse }: HorseSelectionProps) => {
    const currentRound = useSelector(selectRound);
    const currentState = useSelector(selectCurrentState);
    const turnDicesPlayerId = useSelector(selectTurnDicesPlayerId);
    const horses = useSelector(selectHorses);
    const ownHorses = useSelector(selectOwnHorses);
    const currentHorse = useSelector(selectCurrentHorse);

    const currentHorseIndex = currentHorse ? currentHorse.index : -1;
    const turnDicesPlayerHorsesId = horses
        .filter((horse) => horse.playerId === turnDicesPlayerId)
        .map((horse) => horse.index);

    // arrow up: select lower own horse
    useKeyPress(38, () => {
        selectedOwnHorse > 0 && onOwnHorseSelected(selectedOwnHorse - 1);
    });

    // arrow down: select higher own horse
    useKeyPress(40, () => {
        selectedOwnHorse < ownHorses.length - 1 && onOwnHorseSelected(selectedOwnHorse + 1);
    });

    return (
        <div className="flex flex-col w-full h-full p-4">
            <div className="grid gap-2 flex-grow grid-cols-[repeat(auto-fit,minmax(3rem,1fr))] auto-rows-[minmax(3rem,1fr)]">
                {ownHorses.map((horse, index) => {
                    const selected =
                        // prevent horse from being selected while last turn in round is shown with a delay
                        currentState !== Turfmaster.stateMachine.SELECTMOVEOPP &&
                        // prevent horse being selected before first and after last round
                        currentRound >= 0 &&
                        currentRound < Turfmaster.rounds &&
                        currentState === Turfmaster.stateMachine.SELECTDICES
                            ? turnDicesPlayerHorsesId.includes(horse.index)
                            : horse.index === currentHorseIndex;
                    return (
                        <HorseIcon
                            key={horse.index}
                            className={cn('transition-all duration-150 opacity-50 cursor-pointer active:scale-[0.9]', {
                                'opacity-100': selectedOwnHorse === index,
                            })}
                            selected={selected}
                            color={horse.color}
                            onClick={() => onOwnHorseSelected(index)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default memo(HorseSelection);
