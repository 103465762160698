/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { ForkEffect, takeEvery } from 'redux-saga/effects';
import {
    sendAddBonusCards,
    SendAddBonusCards,
    sendMergeCards,
    SendMergeCards,
    sendMessage,
    SendMessage,
    sendSelectCardDice,
    SendSelectCardDice,
    sendSelectDice,
    SendSelectDice,
    sendSelectMove,
    SendSelectMove,
} from '~/overlay';
import { Controller } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';

function* sagaGameSender(controller: Controller): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(
        (action: { type: string }) => /^send/.test(action.type),
        (action: PayloadAction<unknown>) => {
            switch (action.type) {
                case sendSelectCardDice.type:
                    const payloadSelectCardDice = action.payload as SendSelectCardDice;
                    controller.receiveMsg(Turfmaster.stateMachine.SELECTCARDDICE, payloadSelectCardDice);
                    break;
                case sendSelectMove.type:
                    const payloadSelectMove = action.payload as SendSelectMove;
                    controller.receiveMsg(Turfmaster.stateMachine.SELECTMOVE, payloadSelectMove);
                    break;
                case sendSelectDice.type:
                    const payloadSelectDice = action.payload as SendSelectDice;
                    controller.receiveMsg(Turfmaster.stateMachine.SELECTDICES, payloadSelectDice);
                    break;
                case sendMergeCards.type:
                    const payloadMergeCards = action.payload as SendMergeCards;

                    // make sure that the sum of the new card deck is exactly 10 cards
                    // tbd: bonus cards can lead to a different allowed sum
                    if (10 + payloadMergeCards.added.length - payloadMergeCards.removed.length === 10)
                        controller.receiveMsg(Turfmaster.stateMachine.MERGECARDS, payloadMergeCards);
                    break;
                case sendAddBonusCards.type:
                    const payloadAddBonusCards = action.payload as SendAddBonusCards;
                    controller.receiveMsg(Turfmaster.stateMachine.ADDBONUSCARDS, payloadAddBonusCards);
                    break;
                case sendMessage.type:
                    const payloadMessage = action.payload as SendMessage;
                    controller.receiveMsg(Turfmaster.stateMachine.SENDMESSAGE, payloadMessage);
                    break;
            }
        },
    );
}

export default sagaGameSender;
