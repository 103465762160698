/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import useWindowSize from './useWindowSize';

interface FullscreenImageSize {
    innerWidth: number;
    innerHeight: number;
    imgWidth: number;
    imgHeight: number;
    offsetX: number;
    offsetY: number;
}

/**
 * React Hook for automatically calculating the correct fullscreen
 * image sizes based on the inner size of the document. Sizes are
 * adapted to the current width / height ratio and delivered with
 * a fitting offset so that the image is contained and never
 * stretched or cut off.
 *
 * @param orgWidth original width of image that will use the returned sizes in px
 * @param orgHeight original height of image that will use the returned sizes in px
 * @param minPaddingX vertical padding around the image as a percentage of the inner window width
 * @param minPaddingY horizontal padding around the image as a percentage of the inner window height
 *
 * @returns instance of {@link FullscreenImageSize}
 */
const useFullscreenImageSize = (
    orgWidth: number,
    orgHeight: number,
    minPaddingX = 0,
    minPaddingY = 0,
): FullscreenImageSize => {
    const { innerWidth, innerHeight } = useWindowSize();
    const paddingX = innerWidth * minPaddingX;
    const paddingY = innerHeight * minPaddingY;
    const width = innerWidth - paddingX * 2;
    const height = innerHeight - paddingY * 2;

    let imgWidth = 0,
        imgHeight = 0,
        offsetX = 0,
        offsetY = 0;
    if (width > height) {
        imgHeight = height;
        imgWidth = (height * orgWidth) / orgHeight;
        offsetX = (width - imgWidth) / 2 + paddingX;
        offsetY = paddingY;
    } else {
        imgWidth = width;
        imgHeight = (width * orgHeight) / orgWidth;
        offsetX = paddingX;
        offsetY = (height - imgHeight) / 2 + paddingY;
    }
    return { innerWidth, innerHeight, imgWidth, imgHeight, offsetX, offsetY };
};

export default useFullscreenImageSize;
