/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import groupBy from 'lodash/groupBy';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Countdown, { CountdownRendererFn, zeroPad } from 'react-countdown';
import { useSelector } from 'react-redux';
import TextTransition, { presets } from 'react-text-transition';
import {
    HorseColor,
    horseColorIndex,
    selectCurrentState,
    selectGameStartDate,
    selectGameType,
    selectHorseAmount,
    selectHorses,
    selectLastCard,
    selectLastCardHorseIndex,
    selectLastRoundFinished,
    selectLastTurnDicePlayerId,
    selectLastTurnDices,
    selectLastTurnDicesCur,
    selectPlayerId,
    selectPlayers,
    selectRound,
    selectTurnDicesPlayerId,
    selectTurnOrder,
    selectTurnPos,
    selectTurnType,
    useTranslation,
} from '~/app';
import { Horse } from '~/board';
import { GameType } from '~/server/src/dtos';
import Turfmaster from '~/server/src/tmmodel';
import { cn } from '~/utils/styles';
import { CardValue } from '../bar-bottom/Card';
import Dice from '../turn-controls/Dice';
import BarTopHorse from './BarTopHorse';
import FloatingMenu, { FloatingMenuItemKey } from './FloatingMenu';
import LastCard from './LastCard';

// const TextTransition = lazy(() => import('react-text-transition'));

const countdownRenderer: CountdownRendererFn = ({ hours, minutes, seconds }) => {
    return (
        <span className="font-mono font-bold align-middle">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );
};

interface BarTopProps {
    onFloatingMenuItemClicked: (key: FloatingMenuItemKey) => void;
    isDiceShuffling: boolean;
}

const BarTop = ({ onFloatingMenuItemClicked, isDiceShuffling }: BarTopProps) => {
    const { t } = useTranslation();

    const horseAmount = useSelector(selectHorseAmount);
    const horses = useSelector(selectHorses);
    const players = useSelector(selectPlayers);
    const playerId = useSelector(selectPlayerId);
    const gameType = useSelector(selectGameType);
    const gameStartDate = useSelector(selectGameStartDate);
    const currentState = useSelector(selectCurrentState);
    const round = useSelector(selectRound);
    const turnOrder = useSelector(selectTurnOrder);
    const turnDicePlayerId = useSelector(selectTurnDicesPlayerId);
    const turnPos = useSelector(selectTurnPos);
    const turnType = useSelector(selectTurnType);
    const lastCardHorseId = useSelector(selectLastCardHorseIndex);
    const lastCard = useSelector(selectLastCard);
    const lastTurnDicePlayerId = useSelector(selectLastTurnDicePlayerId);
    const lastTurnDicePlayerName =
        lastTurnDicePlayerId >= 0 && players[lastTurnDicePlayerId] ? players[lastTurnDicePlayerId].name : '';
    const lastTurnDices = useSelector(selectLastTurnDices);
    const lastTurnDicesCur = useSelector(selectLastTurnDicesCur);

    const currentHorseIndex = turnOrder[turnPos];
    const currentHorse = horses[currentHorseIndex];
    const currentPlayerId = currentHorse ? currentHorse.playerId : -1;
    const currentPlayer = currentPlayerId >= 0 ? players[currentHorse.playerId] : null;
    const currentPlayerName = currentPlayer ? players[currentHorse.playerId].name : '';
    const currentTurnThrowDicePlayerName =
        turnDicePlayerId >= 0 && players[turnDicePlayerId] ? players[turnDicePlayerId].name : '';
    const lastRoundFinished = useSelector(selectLastRoundFinished);

    const lastCardColor = horses && horses[lastCardHorseId] && horses[lastCardHorseId].color;

    const horsesGroupedByPlayer: Horse[][] = useMemo(() => Object.values(groupBy(horses, 'playerId')), [horses]);
    const horsesPerPlayer = useMemo(
        () => (horsesGroupedByPlayer && horsesGroupedByPlayer[0] ? horsesGroupedByPlayer[0].length : 0),
        [horsesGroupedByPlayer],
    );
    const playerCount = useMemo(() => horseAmount / horsesPerPlayer, [horseAmount, horsesPerPlayer]);
    const lastDiceColor =
        horsesGroupedByPlayer &&
        horsesGroupedByPlayer.length >= lastTurnDicePlayerId + 1 &&
        horsesGroupedByPlayer[lastTurnDicePlayerId] &&
        horsesGroupedByPlayer[lastTurnDicePlayerId].length > 0 &&
        horsesGroupedByPlayer[lastTurnDicePlayerId][0].color;

    const setupPreviewHorses: Horse[][] = useMemo(
        () =>
            Array.from(
                // how many players are missing / have to join?
                { length: playerCount - horses.length / horsesPerPlayer },
                (_v, k) => k,
            ).map<Horse[]>(() =>
                // give the missing players mocked horses with the same amount every player gets
                Array.from({ length: horsesPerPlayer }, (_v, k) => k).map(() => ({
                    index: -1,
                    playerId: -1,
                    color: horseColorIndex.BLACK,
                    jockeyName: '',
                    realColor: horseColorIndex.BLACK,
                    name: '',
                })),
            ),
        [horses.length, horsesPerPlayer, playerCount],
    );

    // determine status in top bar
    const statusRound = useMemo(() => {
        return t('game_statusRoundNumber', { placeholders: { round: Math.min(round + 1, Turfmaster.rounds) } });
    }, [round, t]);
    const statusTurnType = useMemo(() => {
        return turnType === Turfmaster.turntypes.CARDS
            ? t('game_statusRoundTypeCards')
            : t('game_statusRoundTypeDices');
    }, [t, turnType]);
    const statusTextLeft = lastRoundFinished ? '' : `${statusRound} | ${statusTurnType}`;
    const statusTextRight =
        lastTurnDicePlayerName && lastTurnDicePlayerName.length
            ? t('game_statusLastRoll', { placeholders: { playerName: lastTurnDicePlayerName } })
            : '';
    const { statusText, isTournamentCountdown } = useMemo<{
        statusText: string;
        isTournamentCountdown?: boolean;
    }>(() => {
        if (horses && horses.length > 0) {
            // select a card or a dice / also shown when bonus card was selected
            if (
                currentState === Turfmaster.stateMachine.SELECTCARDDICE ||
                currentState === Turfmaster.stateMachine.ADDBONUSCARDSOPP
            ) {
                // select a card
                if (turnType === Turfmaster.turntypes.CARDS) {
                    if (currentPlayerId === playerId) return { statusText: t('game_statusSelectCardSelf') };
                    else if (currentPlayerName && currentPlayerName.length > 0)
                        return {
                            statusText: t('game_statusSelectCardOpponent', {
                                placeholders: { playerName: currentPlayerName },
                            }),
                        };
                }
                // select one or both dice(s)
                else {
                    if (currentPlayerId === playerId) return { statusText: t('game_statusSelectDiceSelf') };
                    else if (currentPlayerName && currentPlayerName.length > 0)
                        return {
                            statusText: t('game_statusSelectDiceOpponent', {
                                placeholders: { playerName: currentPlayerName },
                            }),
                        };
                }
            }
            // select a move
            else if (currentState === Turfmaster.stateMachine.SELECTMOVE) {
                // select a card
                if (turnType === Turfmaster.turntypes.CARDS) {
                    if (currentPlayerId === playerId) return { statusText: t('game_statusSelectCardSelf') };
                    else if (currentPlayerName && currentPlayerName.length > 0)
                        return {
                            statusText: t('game_statusSelectMoveOpponent', {
                                placeholders: { playerName: currentPlayerName },
                            }),
                        };
                }
                // select one or both dice(s)
                else {
                    if (currentPlayerId === playerId) return { statusText: t('game_statusSelectMoveWithDicesSelf') };
                    else if (currentPlayerName && currentPlayerName.length > 0)
                        return {
                            statusText: t('game_statusSelectMoveOpponent', {
                                placeholders: { playerName: currentPlayerName },
                            }),
                        };
                }
            }
            // throw a dice
            else if (currentState === Turfmaster.stateMachine.SELECTDICES) {
                if (!isDiceShuffling && turnDicePlayerId === playerId)
                    return { statusText: t('game_statusThrowDicesSelf') };
                else if (currentTurnThrowDicePlayerName && currentTurnThrowDicePlayerName.length > 0)
                    return {
                        statusText: t('game_statusSelectMoveOpponent', {
                            placeholders: { playerName: currentTurnThrowDicePlayerName },
                        }),
                    };
            }
            // merge cards
            else if (currentState === Turfmaster.stateMachine.MERGECARDS) {
                if (currentPlayerId === playerId) return { statusText: t('game_statusMergeCardsSelf') };
                else if (currentPlayerName && currentPlayerName.length > 0)
                    return {
                        statusText: t('game_statusMergeCardsOpponent', {
                            placeholders: { playerName: currentPlayerName },
                        }),
                    };
            }
        }

        if (round === 0) {
            switch (gameType) {
                case GameType.Training:
                    return { statusText: t('game_statusWait_training'), isTournamentCountdown: false };
                case GameType.FreeBronze:
                case GameType.FreeSilver:
                case GameType.FreeGold:
                    return {
                        statusText: t('game_statusWait_free', {
                            placeholders: { playerCount: playerCount - players.length },
                            pluralCount: playerCount - players.length,
                        }),
                    };
                case GameType.TournamentBronze:
                case GameType.TournamentSilver:
                case GameType.TournamentGold:
                    return {
                        statusText: '',
                        isTournamentCountdown: true,
                    };
                default:
                    return { statusText: t('game_statusWait_default') };
            }
        }

        // make sure status is at least an empty string
        return { statusText: '' };
    }, [
        horses,
        round,
        currentState,
        turnType,
        currentPlayerId,
        playerId,
        t,
        currentPlayerName,
        isDiceShuffling,
        turnDicePlayerId,
        currentTurnThrowDicePlayerName,
        gameType,
        playerCount,
        players.length,
    ]);

    const lastDices = useMemo(() => {
        return lastTurnDices.map((value, index) => {
            // 1. 'invalid' selection at startup should always be visible
            // 2. visible if all dices are selected
            // 3. visible if this specific dice is selected
            return { value, selected: value < 0 || lastTurnDicesCur > 1 || index === lastTurnDicesCur };
        });
    }, [lastTurnDices, lastTurnDicesCur]);

    const [nowTime, setNowTime] = useState(() => new Date().getTime());

    useEffect(() => {
        if (!isTournamentCountdown) return;

        const interval = setInterval(() => setNowTime(new Date().getTime()), 1000);
        return () => clearInterval(interval);
    }, [isTournamentCountdown]);

    return (
        <div className="absolute flex bg-[#00000055] backface-hidden pointer-events-auto left-0 right-0 w-screen h-[15%] flex-row items-center top-0 rounded-b-lg border-b border-x border-black/50">
            <FloatingMenu className="left-4 top-4 absolute" onItemClicked={onFloatingMenuItemClicked} />

            <div
                className="sm:space-x-8 md:space-x-12 lg:space-x-16 flex items-center self-start justify-center flex-1 px-2 pt-2 mr-4 space-x-4"
                style={{ marginLeft: 'calc(56px + 2rem)' }}>
                {horsesGroupedByPlayer.concat(setupPreviewHorses).map((horsesOfPlayer, playerIndex) => {
                    return (
                        <div key={playerIndex} className="relative flex flex-row items-center space-x-1">
                            {horsesOfPlayer.map((horse, horseIndexOfPlayer) => {
                                return (
                                    <BarTopHorse
                                        key={`${horseIndexOfPlayer} ${playerIndex}`}
                                        horseIndexOfPlayer={horseIndexOfPlayer}
                                        horseIndex={horses.indexOf(horse)}
                                        preview={horse.index === -1}
                                        className="w-12 h-12"
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </div>

            <div className="flex h-[11.5vh] self-start items-center">
                {(isDiceShuffling || turnType === Turfmaster.turntypes.CARDS) &&
                    lastCardHorseId >= 0 &&
                    (lastCard as CardValue) !== undefined && (
                        <LastCard
                            value={lastCard as CardValue}
                            className={cn('w-auto h-[80%] transition-all pr-[8px]', {
                                'pr-[4vw]': !lastDices || lastDices.length <= 0,
                            })}
                            color={lastCardColor}
                        />
                    )}
                {lastDices.map((diceObject, index) => (
                    <Dice
                        key={index}
                        value={diceObject.value}
                        color={lastDiceColor as HorseColor}
                        disabled={!diceObject.selected}
                        className={cn('w-auto h-1/2 transition-all pr-[8px]', {
                            'pr-[4vw]': index >= lastDices.length - 1,
                        })}
                    />
                ))}
            </div>

            <div className="absolute flex flex-row items-end inset-x-[4vw] bottom-[1vh] text-white text-center">
                <div className="flex-1 text-[2vh] leading-[2vh] whitespace-nowrap align-text-bottom overflow-hidden text-clip text-left">
                    {statusTextLeft}
                </div>

                <TextTransition
                    springConfig={presets.gentle}
                    className={cn('text-center h-[4vh] text-clip whitespace-nowrap overflow-hidden', {
                        'opacity-0': isTournamentCountdown,
                    })}>
                    <div className="text-[3vh] leading-[3.25vh]">{statusText}</div>
                </TextTransition>

                {isTournamentCountdown && (
                    <div className="text-[3vh] leading-[3vh] text-clip whitespace-nowrap flex flex-row items-center space-x-2 overflow-hidden text-center align-middle">
                        <span className="align-middle">{t('game_statusWait_tournament')}</span>
                        <Countdown
                            key={`${nowTime}_${gameStartDate}`}
                            date={gameStartDate}
                            renderer={countdownRenderer}
                        />
                    </div>
                )}

                <div className="flex-1 text-[2vh] leading-[2vh] whitespace-nowrap overflow-hidden text-clip text-right">
                    {statusTextRight}
                </div>
            </div>
        </div>
    );
};

export default memo(BarTop);
