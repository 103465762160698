import { match, MatchResult } from 'path-to-regexp';
import URL from 'url-parse';

/**
 * Safely converts urls into path strings. Urls can start with https://
 *
 * @param url full url or path possibly starting with https:// which has to be removed
 */
export const parseUrl = (url: string): string => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        const { pathname } = new URL(url);
        return pathname;
    }
    return url;
};

/**
 * Gets all queries from a URL and returns as dictionary.
 *
 * @param url full url  or path possibly starting with https:// which has to be removed
 */
export const extractQuery = (
    url: string,
): {
    [key: string]: string | undefined;
} => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        const { query } = new URL(url, true);
        return query;
    }
    const query: { [key: string]: string } = {};
    return query;
};

/**
 * Extract parameter from express path style syntax
 *
 * @param url full url or path possibly starting with https://
 * @param matcher string in express path style syntax to indicate params
 */
/* eslint-disable @typescript-eslint/ban-types */
export const extractParams = <T extends object = object>(url: string, matcher: string): T =>
    (match<T>(matcher, { decode: decodeURIComponent })(parseUrl(url)) as MatchResult<T>).params;
