/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHorses, selectPlayers, useTranslation } from '~/app';
import { cn } from '~/utils/styles';
import * as styles from './ActionUI.module.css';
import {
    useBonusCardTrigger,
    useCardAboveHandiTrigger,
    useHorseDroppedTrigger,
    useStartRoundTrigger,
} from './triggers';

const ActionUI = () => {
    const { t } = useTranslation();
    const timeout = useRef<number | undefined>(undefined);
    const horses = useSelector(selectHorses);
    const players = useSelector(selectPlayers);

    const [text, setText] = useState<string | undefined>();
    const animateText = useCallback(
        (value: string) => {
            if (timeout.current !== undefined) {
                if (text === value) return;
                clearTimeout(timeout.current);
                timeout.current = undefined;
                setText(undefined);
                return;
            }
            setText(value);
            timeout.current = window.setTimeout(() => {
                setText(undefined);
                clearTimeout(timeout.current);
                timeout.current = undefined;
            }, 3000);
        },
        [text],
    );

    /* dropped horses */
    useHorseDroppedTrigger(
        useCallback(
            (horseIndex) => {
                if (players && horses && horses[horseIndex] && players[horses[horseIndex].playerId]) {
                    const playerName = players[horses[horseIndex].playerId].name;
                    animateText(t('game_messageHorseDropped', { placeholders: { playerName } }));
                }
            },
            [animateText, horses, players, t],
        ),
    );

    /* new round started message */
    useStartRoundTrigger(useCallback((round: number) => animateText(`Runde ${round + 1} gestartet`), [animateText]));

    /* selected card too high for handicap */
    useCardAboveHandiTrigger(
        useCallback(
            (horseIndex) => {
                if (players && horses && horses[horseIndex] && players[horses[horseIndex].playerId]) {
                    const playerName = players[horses[horseIndex].playerId].name;
                    animateText(t('game_messageHandicap', { placeholders: { playerName } }));
                }
            },
            [animateText, horses, players, t],
        ),
    );

    /* player uses bonuscards */
    useBonusCardTrigger(
        useCallback(
            (horseIndex: number) => {
                const horse = horses.find((h) => h.index === horseIndex);
                const playerName = horse ? players[horse.playerId].name : '';
                animateText(t('game_messageBonuscards', { placeholders: { playerName } }));
            },
            [animateText, horses, players, t],
        ),
    );

    return (
        <div className={cn(styles.ActionUIRoot, { [styles.ActionUIRootVisible]: text && text.length > 0 })}>
            <div className={cn(styles.ActionUIText, { [styles.ActionUITextVisible]: text && text.length > 0 })}>
                {text}
            </div>
        </div>
    );
};

export default memo(ActionUI);
