/**
 * @author       Andreas Bayer <andreas@plusbyte.de>
 * @copyright    2015 Plusbyte UG (haftungsbeschränkt)
 * @license      {@link https://plusbyte.de Plusbyte License}
 */

import { io, Socket } from 'socket.io-client';
import { Controller, ControllerType } from './interfaces';
import Turfmaster, { Board, Game, Horse } from './tmmodel';
import { ChatMessage, GameState } from './dtos';

let self: ControllerRemote | null = null;

/**
 * Controller
 * @class Turfmaster.Controller
 * @constructor
 * @param {Turfmaster} model - Model
 */
class ControllerRemote implements Controller {
    public playerView: any;

    public board = new Board(0);

    public game = new Game();

    public chatMessages: ChatMessage[] = [];

    public userId: string | null = null;

    public serverTime: string | null = null;

    private connectionPromise: Promise<void>;

    public getType() {
        return ControllerType.REMOTE;
    }

    public getGame() {
        return this.game;
    }

    public getChatMessages() {
        return this.chatMessages;
    }

    public getBoard() {
        return this.board;
    }

    public socket: Socket | undefined;

    public constructor(
        host: string,
        token = 'asdfeyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoidGVzdCIsImlhdCI6MTU3MjQ1OTU1OCwiZXhwIjoxNTcyNTQ1OTU4fQ.PcTNJJb4ar4i2wA-hnEreGSMClfqsBGm69_IbscY7gg',
    ) {
        self = this;
        console.log('Connect to socket ' + host);

        this.connectionPromise = new Promise((resolve, reject) => {
            this.socket = io(host, {
                auth: { token: `Bearer ${token}` },
            });

            this.socket.on('connect', function () {
                console.log('Connected!');
            });

            this.socket.on('connectionparams', function (message: { userId: string; serverTime: string }) {
                self!.userId = message.userId;
                self!.serverTime = message.serverTime;
                resolve();
            });

            this.socket.on('unauthorized', function (error: any, callback: () => void) {
                if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
                    callback();
                    console.log("User's token has expired!");
                } else {
                    console.log("User's token is invalid, unknown error");
                }
                reject(error);
            });

            this.socket.on('connect_error', function (error: any) {
                if (error.type == 'UnauthorizedError' || error.code == 'invalid_token') {
                    console.log("User's token has expired!");
                } else {
                    console.log("User's token is invalid, unknown error");
                }
                reject(error);
            });
        });

        if (!this.socket) {
            throw new Error('Socket not connected');
        }

        this.socket.on('disconnect', function () {
            console.log('Disconnected!');
        });

        this.socket.on('getgames', function (message: any) {
            self!.sendMsg(Turfmaster.stateMachine.GETGAMES, message);
        });

        this.socket.on('gettournaments', function (message: any) {
            self!.sendMsg(Turfmaster.stateMachine.GETTOURNAMENTS, message);
        });

        this.socket.on('newgame', function (message: { game: Game; state: number; params: Record<string, unknown> }) {
            self!.game = message.game;
            self!.board = new Board(self!.game.boardid);

            self!.sendMsg(message.state, message.params);
        });

        this.socket.on('restoregame', function (message: { game: GameState | null }) {
            if (message.game && Array.isArray(message.game.chatMessages)) {
                self!.chatMessages = message.game.chatMessages;
            }

            self!.sendMsg(Turfmaster.stateMachine.RESTOREGAME, message);
        });

        this.socket.on(
            'joingame',
            function (message: {
                game: Game;
                state: number;
                params: { playerid: string; chatmessages: ChatMessage[] };
            }) {
                self!.game = message.game;
                self!.board = new Board(self!.game.boardid);
                self!.chatMessages = message.params.chatmessages;

                self!.sendMsg(message.state, message.params);
            },
        );

        this.socket.on('joingameerror', function (message: { state: number; params: Record<string, unknown> }) {
            self!.sendMsg(message.state, message.params);
        });

        this.socket.on(
            'jointournament',
            function (message: { game: Game; state: number; params: Record<string, unknown> }) {
                self!.game = message.game;
                self!.board = new Board(self!.game.boardid);

                self!.sendMsg(message.state, message.params);
            },
        );

        this.socket.on('jointournamenterror', function (message: { state: number; params: Record<string, unknown> }) {
            self!.sendMsg(message.state, message.params);
        });

        this.socket.on('rejoingame', function (message: { game: Game }) {
            self!.game = message.game;
            self!.board = new Board(self!.game.boardid);

            self!.sendMsg(Turfmaster.stateMachine.REJOINGAME, message);
        });

        this.socket.on('rejoingameerror', function (message: Record<string, unknown>) {
            self!.sendMsg(Turfmaster.stateMachine.REJOINGAME, message);
        });

        this.socket.on('gamecommand', function (message: { game: Game; state: number; params: any }) {
            self!.game = message.game;

            self!.sendMsg(message.state, message.params);
        });

        this.socket.on('newmessage', function (message: { state: number; params: { message: ChatMessage } }) {
            self!.chatMessages = [...self!.chatMessages, message.params.message];

            self!.sendMsg(Turfmaster.stateMachine.NEWMESSAGE, message);
        });
    }

    public async waitForConnection() {
        return this.connectionPromise;
    }

    public receiveMsg(state: any, params: any) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const gameid = 0;

        switch (state) {
            case Turfmaster.stateMachine.GETGAMES:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('getgames', null);
                break;
            case Turfmaster.stateMachine.GETTOURNAMENTS:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('gettournaments', null);
                break;
            case Turfmaster.stateMachine.NEWGAME:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('newgame', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.RESTOREGAME:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('restoregame', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.JOINGAME:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('joingame', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.JOINTOURNAMENT:
                this.playerView = params.view;
                delete params.view;

                this.socket!.emit('jointournament', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.REJOINGAME:
                this.playerView = params.view;
                delete params.view;

                delete params.gameid;

                this.socket!.emit('rejoingame', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.MERGECARDS:
            case Turfmaster.stateMachine.ADDBONUSCARDS:
            case Turfmaster.stateMachine.SELECTCARDDICE:
            case Turfmaster.stateMachine.SELECTMOVE:
            case Turfmaster.stateMachine.SELECTDICES:
                this.socket!.emit('gamecommand', { state: state, params: params });
                break;
            case Turfmaster.stateMachine.SENDMESSAGE:
                this.socket!.emit('sendmessage', { state: state, params: params });
                break;
        }
    }

    public sendMsg(state: number, params: any) {
        switch (state) {
            case Turfmaster.stateMachine.GETGAMES:
            case Turfmaster.stateMachine.GETTOURNAMENTS:
            case Turfmaster.stateMachine.NEWGAME:
            case Turfmaster.stateMachine.RESTOREGAME:
            case Turfmaster.stateMachine.JOINGAME:
            case Turfmaster.stateMachine.JOINTOURNAMENT:
            case Turfmaster.stateMachine.JOINGAMEOPP:
            case Turfmaster.stateMachine.REJOINGAME:
            case Turfmaster.stateMachine.STARTGAME:
            case Turfmaster.stateMachine.STARTROUND:
            case Turfmaster.stateMachine.MERGECARDS:
            case Turfmaster.stateMachine.MERGECARDSOPP:
            case Turfmaster.stateMachine.STARTTURN:
            case Turfmaster.stateMachine.ADDBONUSCARDSOPP:
            case Turfmaster.stateMachine.SELECTCARDDICE:
            case Turfmaster.stateMachine.SELECTMOVE:
            case Turfmaster.stateMachine.SELECTMOVEOPP:
            case Turfmaster.stateMachine.SELECTDICES:
            case Turfmaster.stateMachine.SELECTDICESOPP:
            case Turfmaster.stateMachine.ENDTURN:
            case Turfmaster.stateMachine.ENDROUND:
            case Turfmaster.stateMachine.ENDGAME:
            case Turfmaster.stateMachine.NEWMESSAGE:
                if (!this.playerView) {
                    console.log('Error: sendMsg - playerView is not set');
                }

                this.playerView.receiveMsg(state, params);
                break;
        }
    }

    /**
     * getDices
     * @method Turfmaster.Controller#getDices
     */
    public getDices() {
        const dices = this.game.turndices;
        const id = this.game.turndicescur;

        if (dices[id]) {
            return [dices[id]];
        } else {
            return dices;
        }
    }

    /**
     * getHorseId
     * @method Turfmaster.Controller#getHorseId
     */
    public getHorseId(): number {
        return this.game.turnorder[this.game.turnpos];
    }

    public getHorse(horseid: number): Horse {
        if (!this.game.horses[horseid]) {
            throw new Error('getHorse - Horseid ' + horseid + ' is not valid');
        }

        return this.game.horses[horseid];
    }

    /**
     * GetCurrentCards
     * @method Turfmaster.Controller#getCurrentCards
     */
    public getCurrentCards(horseid: number): number[] {
        const horse = this.getHorse(horseid);

        if (!horse.cards[this.game.round]) {
            throw new Error('getLastCards - Round ' + this.game.round + ' not valid');
        }

        return horse.cards[this.game.round];
    }

    /**
     * GetLastCards
     * @method Turfmaster.Controller#getLastCards
     */
    public getLastCards(horseid: number): number[] {
        const horse = this.getHorse(horseid);

        if (!horse.cards[this.game.round - 1]) {
            throw new Error('getLastCards - Round ' + (this.game.round - 1) + ' not valid');
        }

        return horse.cards[this.game.round - 1];
    }

    /**
     * getPlayerId
     * @method Turfmaster.Controller#getPlayerId
     */
    public getPlayerId(): number {
        const horseid = this.getHorseId();
        const horse = this.getHorse(horseid);

        return horse.playerid;
    }

    public isHurdle(lane: number, pos: number): boolean {
        if (
            Turfmaster.hurdleRounds.indexOf(this.game.round) !== -1 &&
            this.board.fields[lane] &&
            this.board.fields[lane][pos] &&
            this.board.fields[lane][pos].hurdle
        ) {
            return true;
        }

        return false;
    }

    public closeConnection = () => {
        this.socket?.disconnect();
        delete this.socket;
    };
}

export default ControllerRemote;
