/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SetEntityPropertyParams } from 'app';
import { ChatMessage } from '~/server/src/dtos';
import {
    setUserId,
    setCardDice,
    setChatMessages,
    setHandiFailureHorseIndex,
    setHorseBonusCards,
    setHorseCards,
    setHorsesBonusCards,
    setHorsesCards,
    setLastCard,
    setLastCardHorseIndex,
    setLastTurnDicePlayerId,
    setLastTurnDices,
    setLastTurnDicesCur,
    setOwnHorseRemovedCards,
    setSelectedCardIndexLocal,
    setSelectedDicesLocal,
} from './actions';

export interface OverlayState {
    readonly handiFailureHorseIndex: number;
    readonly cardDice: number;
    readonly lastCardHorseIndex: number;
    readonly lastCard: number;
    readonly lastTurnDicePlayerId: number;
    readonly lastTurnDices: number[];
    readonly lastTurnDicesCur: number;

    readonly horsesCards: number[][][];
    readonly horsesBonusCards: number[];
    readonly ownHorseRemovedCards: number[];

    readonly selectedDicesLocal: number;
    readonly selectedCardIndexLocal: number;
    readonly chatMessages: ChatMessage[];
    readonly userId: string;
}

export const initialOverlayState: OverlayState = {
    handiFailureHorseIndex: -1,
    cardDice: -1,
    lastCardHorseIndex: -1,
    lastCard: -1,
    lastTurnDicePlayerId: -1,
    lastTurnDices: [],
    lastTurnDicesCur: -1,
    horsesCards: [],
    horsesBonusCards: [],
    ownHorseRemovedCards: [],
    selectedDicesLocal: -1,
    selectedCardIndexLocal: -1,
    chatMessages: [],
    userId: '',
};

export default createReducer(initialOverlayState, {
    // TODO: webpack error "Cannot read property 'type' of undefined" as actions, fix later
    startTraining: () => initialOverlayState,
    startFree: () => initialOverlayState,
    startDebug: () => initialOverlayState,
    startTournament: () => initialOverlayState,

    [setHandiFailureHorseIndex.type]: (state, action: PayloadAction<number>) => {
        state.handiFailureHorseIndex = action.payload;
    },
    [setCardDice.type]: (state, action: PayloadAction<number>) => {
        state.cardDice = action.payload;
    },
    [setLastCardHorseIndex.type]: (state, action: PayloadAction<number>) => {
        state.lastCardHorseIndex = action.payload;
    },
    [setLastCard.type]: (state, action: PayloadAction<number>) => {
        state.lastCard = action.payload;
    },
    [setLastTurnDicePlayerId.type]: (state, action: PayloadAction<number>) => {
        state.lastTurnDicePlayerId = action.payload;
    },
    [setLastTurnDices.type]: (state, action: PayloadAction<number[]>) => {
        state.lastTurnDices = action.payload;
    },
    [setLastTurnDicesCur.type]: (state, action: PayloadAction<number>) => {
        state.lastTurnDicesCur = action.payload;
    },
    [setHorsesCards.type]: (state, action: PayloadAction<number[][][]>) => {
        state.horsesCards = action.payload;
    },
    [setHorseCards.type]: (state, action: PayloadAction<SetEntityPropertyParams<number[][]>>) => {
        state.horsesCards[action.payload.index] = action.payload.value;
    },
    [setHorsesBonusCards.type]: (state, action: PayloadAction<number[]>) => {
        state.horsesBonusCards = action.payload;
    },
    [setHorseBonusCards.type]: (state, action: PayloadAction<SetEntityPropertyParams<number>>) => {
        state.horsesBonusCards[action.payload.index] = action.payload.value;
    },
    [setOwnHorseRemovedCards.type]: (state, action: PayloadAction<number[]>) => {
        state.ownHorseRemovedCards = action.payload;
    },
    [setSelectedDicesLocal.type]: (state, action: PayloadAction<number>) => {
        state.selectedDicesLocal = action.payload;
    },
    [setSelectedCardIndexLocal.type]: (state, action: PayloadAction<number>) => {
        state.selectedCardIndexLocal = action.payload;
    },
    [setChatMessages.type]: (state, action: PayloadAction<ChatMessage[]>) => {
        state.chatMessages = action.payload;
    },
    [setUserId.type]: (state, action: PayloadAction<string>) => {
        state.userId = action.payload;
    },
});
