import { Link } from '@mui/material';
import React, { useState } from 'react';
import {
    useClearDatabase,
    useClearDatabaseFirebase,
    useClearDatabaseLocal,
    useUpdateAllRankGold,
    useUpdateAllRankSilver,
} from '~/apis';
import { cn } from '~/utils/styles';

interface AdminControlsProps {
    className?: string;
}

const AdminControls = ({ className }: AdminControlsProps): JSX.Element => {
    const [isSafetySwitchDisabled, setIsSafetySwitchDisabled] = useState(false);

    const updateAllRankSilver = useUpdateAllRankSilver();
    const updateAllRankGold = useUpdateAllRankGold();

    const clearDatabase = useClearDatabase();
    const clearDatabaseLocal = useClearDatabaseLocal();
    const clearDatabaseFirebase = useClearDatabaseFirebase();

    return (
        <div className={cn('flex flex-col space-y-2', className)}>
            <h2 className="font-bold">Administrator Funktionen</h2>
            {isSafetySwitchDisabled ? (
                <div className="flex flex-col items-start mb-2">
                    <Link underline="hover" className="cursor-pointer" onClick={() => setIsSafetySwitchDisabled(false)}>
                        ...Oder auch nicht, bring mich weg hier!
                    </Link>

                    <div className="h-2" />

                    <Link underline="hover" className="cursor-pointer" onClick={() => updateAllRankSilver.mutate()}>
                        Setze den Status aller Nutzer auf "silber"
                    </Link>
                    <Link underline="hover" className="cursor-pointer" onClick={() => updateAllRankGold.mutate()}>
                        Setze den Status aller Nutzer auf "gold"
                    </Link>
                    <Link underline="hover" className="cursor-pointer" onClick={() => clearDatabase.mutate()}>
                        Lösche alle Daten der DB
                    </Link>
                    <Link underline="hover" className="cursor-pointer" onClick={() => clearDatabaseLocal.mutate()}>
                        Lösche lokale Daten der DB
                    </Link>
                    <Link underline="hover" className="cursor-pointer" onClick={() => clearDatabaseFirebase.mutate()}>
                        Lösche Firebase Daten der DB
                    </Link>
                </div>
            ) : (
                <div className="flex flex-col items-start">
                    <Link underline="hover" className="cursor-pointer " onClick={() => setIsSafetySwitchDisabled(true)}>
                        Ich weiß was ich tue...
                    </Link>
                </div>
            )}
        </div>
    );
};

export default AdminControls;
