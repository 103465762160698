/**
 * @author       Andreas Bayer <andreas@plusbyte.de>
 * @copyright    2015 Plusbyte UG (haftungsbeschränkt)
 * @license      {@link https://plusbyte.de Plusbyte License}
 */

export type StateMachineKey =
    | 'GETGAMES'
    | 'NEWGAME'
    | 'RESTOREGAME'
    | 'JOINGAME'
    | 'JOINGAMEOPP'
    | 'REJOINGAME'
    | 'STARTGAME'
    | 'STARTROUND'
    | 'MERGECARDS'
    | 'MERGECARDSOPP'
    | 'STARTTURN'
    | 'ADDBONUSCARDS'
    | 'ADDBONUSCARDSOPP'
    | 'SELECTCARDDICE'
    | 'SELECTMOVE'
    | 'SELECTMOVEOPP'
    | 'SELECTDICES'
    | 'SELECTDICESOPP'
    | 'ENDTURN'
    | 'ENDROUND'
    | 'ENDGAME'
    | 'GETTOURNAMENTS'
    | 'JOINTOURNAMENT'
    | 'NEWMESSAGE'
    | 'SENDMESSAGE';

export type StateMatrixKey = 8 | 12 | 13 | 14 | 16;

type PlayerTypeKey = 'PLAYER' | 'COMPUTER';

type TurnTypeKey = 'CARDS' | 'DICES';

/**
 * @namespace Turfmaster
 */
class Turfmaster {
    /**
     * Turn types
     * @constant
     * @property {Number} turnTypes.CARDS - Cards
     * @property {Number} turnTypes.DICES - Dices
     */
    public static readonly turntypes: { [key in TurnTypeKey]: number } = {
        CARDS: 0,
        DICES: 1,
    };

    /**
     * State Machine
     * @constant
     * @property {Number} stateMachine.NONE - None
     * @property {Number} stateMachine.NEWGAME - NewGameRequest
     * @property {Number} stateMachine.STARTGAME - StartGame
     * @property {Number} stateMachine.STARTROUND - StartRound
     * @property {Number} stateMachine.MERGECARDS - MergeCards
     * @property {Number} stateMachine.MERGECARDSOPP - MergeCardsOpponent
     * @property {Number} stateMachine.STARTTURN - StartTurn
     * @property {Number} stateMachine.ADDBONUSCARDS - AddBonusCards
     * @property {Number} stateMachine.SELECTCARDDICE - SelectCardDice
     * @property {Number} stateMachine.SELECTMOVE - SelectMove
     * @property {Number} stateMachine.SELECTMOVEOPP - SelectMoveOpponent
     * @property {Number} stateMachine.SELECTDICES - SelectDices
     * @property {Number} stateMachine.SELECTDICESOPP - SelectDicesOpponent
     * @property {Number} stateMachine.ENDTURN - EndTurn
     * @property {Number} stateMachine.ENDROUND - EndRound
     * @property {Number} stateMachine.ENDGAME - EndGame
     */
    public static readonly stateMachine: { [key in StateMachineKey]: number } = {
        GETGAMES: 0,
        NEWGAME: 1,
        RESTOREGAME: 2,
        JOINGAME: 3,
        JOINGAMEOPP: 4,
        REJOINGAME: 5,
        STARTGAME: 6,
        STARTROUND: 7,
        MERGECARDS: 8,
        MERGECARDSOPP: 9,
        STARTTURN: 10,
        ADDBONUSCARDS: 11,
        ADDBONUSCARDSOPP: 12,
        SELECTCARDDICE: 13,
        SELECTMOVE: 14,
        SELECTMOVEOPP: 15,
        SELECTDICES: 16,
        SELECTDICESOPP: 17,
        ENDTURN: 18,
        ENDROUND: 19,
        ENDGAME: 20,
        GETTOURNAMENTS: 21,
        JOINTOURNAMENT: 22,
        NEWMESSAGE: 23,
        SENDMESSAGE: 24,
    };

    /**
     * State Matrix
     * @constant
     * @type {Object}
     */
    public static readonly stateMatrix: { [key in StateMatrixKey]: number[] } = {
        8: [8, 11],
        12: [8, 13, 14, 16],
        13: [11, 13, 14],
        14: [11, 13, 14],
        16: [11, 16],
    };

    /**
     * Player Types
     * @constant
     * @property {Number} playerTypes.PLAYER
     * @property {Number} playerTypes.COMPUTER
     */
    public static readonly playerTypes: { [key in PlayerTypeKey]: number } = {
        PLAYER: 0,
        COMPUTER: 1,
    };

    /**
     * CompStrengths
     * @constant
     * @property {Number} compStrengths.EASY
     * @property {Number} compStrengths.MEDIUM
     * @property {Number} compStrengths.STRONG
     */
    public static readonly compStrengths = {
        EASY: 0,
        MEDIUM: 1,
        STRONG: 2,
    };

    /**
     * CardTypes -- maybe replace with CardType enum?
     * @constant
     * @property {Number} cardTypes.BRONZE
     * @property {Number} cardTypes.SILVER
     * @property {Number} cardTypes.GOLD
     */
    public static readonly cardTypes = {
        BRONZE: 0,
        SILVER: 1,
        GOLD: 2,
    };

    /**
     * Cards bronze
     * @constant
     * @type {Array}
     */
    public static readonly cardsBronze = [
        -11, -10, -9, 3, 3, 4, 4, 6, 6, 6, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 11, 11, 12,
    ];

    /**
     * Cards silver
     * @constant
     * @type {Array}
     */
    public static readonly cardsSilver = [
        -11, -10, -9, 3, 3, 4, 4, 6, 6, 6, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 12, 12,
    ];

    /**
     * Cards gold
     * @constant
     * @type {Array}
     */
    public static readonly cardsGold = [
        -12, -11, -10, -9, 3, 3, 4, 4, 6, 6, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 12, 12,
    ];

    /**
     * Points
     * @constant
     * @type {Array}
     */
    public static readonly points = [50, 30, 20, 10];

    /**
     * Handi
     * @constant
     * @type {Array}
     */
    public static readonly handi = [8, 9, 10];

    /**
     * Dices
     * @constant
     * @type {Number}
     */
    public static readonly dices = 2;

    /**
     * Min horses
     * @constant
     * @type {Number}
     */
    public static readonly minHorses = 4;

    /**
     * Max horses
     * @constant
     * @type {Number}
     */
    public static readonly maxHorses = 8;

    /**
     * Rounds
     * @constant
     * @type {Number}
     */
    public static readonly rounds = 3;

    /**
     * Hurdle Rounds
     * @constant
     * @type {Array}
     */
    public static readonly hurdleRounds = [2];

    /**
     * Available timeouts which determine how long each turn of a computer or human player
     * is allowed too last. The turn of a human player will be calculated automatically after
     * the timeout and a computer player uses the timeout as a maximum turn time.
     */
    public static readonly timeouts = [10, 30, 60];

    /**
     * Timeout (ms) after one horse has finished their turn
     * @type {Number}
     */
    public static readonly finishedHorsesTurnTimeout = 5000;

    /**
     * Timeout (ms) before each round restarts of game ends
     * @type {Number}
     */
    public static readonly endRoundTimeout = 10000;
}

class Game {
    public name = '';

    public horseamount = 0;

    /**
     * @property {Number} boardid - Boardid
     */
    public boardid = 0;

    /**
     * @property {Array} players - Players
     */
    public players: Player[] = [];

    /**
     * @property {Array} horses - Horses
     */
    public horses: Horse[] = [];

    /**
     * @property {Number} round - Round
     */
    public round = 0;

    /**
     * @property {Array} turnorder - Turnorder
     */
    public turnorder: number[] = [];

    /**
     * @property {Number} turnpos - Turnpos
     */
    public turnpos = 0;

    /**
     * @property {Number} turndices - Turndices
     */
    public turndices: number[] = [];

    /**
     * @property {Number} turndicescur - Turndicescur
     */
    public turndicescur = 0;

    /**
     * @property {Turfmaster.turntypes} turntype - Turntype
     */
    public turntype = Turfmaster.turntypes.CARDS;

    /**
     * @property {Number} turndiceplayerid - Turndiceplayerid
     */
    public turndiceplayerid = 0;

    /**
     * @property {Turfmaster.stateMachine} currentState - CurrentState
     */
    public currentState = Turfmaster.stateMachine.NEWGAME;
}

class Player {
    public userid = '';

    public name = '';

    public country = 'DE';

    public type = Turfmaster.playerTypes.PLAYER;

    public totalrank = -1;
}

class Horse {
    public horseid = '';

    public playerid = 0;

    public name = '';

    public color = -1;

    public jockeyName = '';

    public cardType = Turfmaster.cardTypes.BRONZE;

    public cards: number[][] = [];

    public rank = -1;

    public totalrank = -1;

    public handi = -1;

    public field: Field | null = null;

    public points: number[] = [];

    public ranks: number[] = [];

    public bonuscards = -1;

    public finished = false;

    public dropped = false;
}

class Move {
    public fields: Field[] = [];

    public drop = false;
}

class Board {
    private boardid: number;
    public goal: number;
    public fields: Field[][];

    public constructor(boardid: number) {
        this.boardid = boardid;
        this.goal = this.createGoal(/*boardid*/);
        this.fields = this.createFields(/*boardid*/);
    }

    private createGoal = (/*boardid: number*/) => {
        return 114;
    };

    private createFields = (/*boardid: number*/) => {
        const straightPath = (
            fields: any,
            startpoint: any,
            count: any,
            lanes: any,
            startx: any,
            starty: any,
            width: any,
            height: any,
            vert: any,
            dir: any,
        ) => {
            for (let i = 0; i < lanes; i++) {
                for (let j = 0; j < count; j++) {
                    const field = new Field();
                    fields[i].push(field);
                    field.lane = i;
                    field.pos = fields[i].length - 1;
                    field.prog = startpoint + j + 1;
                    if (!(i >= 6 && j === count - 1)) {
                        field.nf = fields[i].length;
                    } else {
                        field.nf = -1;
                    }
                    if (i > 0 && !(i >= 6 && j === count - 1)) {
                        field.nl = fields[i - 1].length - count + j + 1;
                    } else {
                        field.nl = -1;
                    }
                    if (i < lanes - 1 && !(i >= 5 && j === count - 1)) {
                        field.nr = fields[i + 1].length + j + 1;
                    } else {
                        field.nr = -1;
                    }
                    if (vert === true) {
                        if (dir === true) {
                            field.x = startx + (lanes - i - 1) * width;
                            field.y = starty + j * height;
                        } else {
                            field.x = startx + i * width;
                            field.y = starty + (count - j - 1) * height;
                        }
                    } else {
                        if (dir === true) {
                            field.x = startx + j * width;
                            field.y = starty + i * height;
                        } else {
                            field.x = startx + (count - j - 1) * width;
                            field.y = starty + (lanes - i - 1) * height;
                        }
                    }
                }
            }
        };

        const curvedPath = (
            fields: any,
            startpoint: any,
            count: any,
            lanes: any,
            startx: any,
            starty: any,
            degree: any,
            radius: any,
            diff: any,
            dir: any,
        ) => {
            for (let i = 0; i < lanes; i++) {
                for (let j = 0; j < count - 6 + i + 1; j++) {
                    const field = new Field();
                    fields[i].push(field);
                    field.lane = i;
                    field.pos = fields[i].length - 1;
                    field.prog = startpoint + (14 / (count - 5 + i)) * (j + 1);
                    if (!(i >= 6 && j === count - 1)) {
                        field.nf = fields[i].length;
                    } else {
                        field.nf = -1;
                    }
                    if (i > 0) {
                        field.nl = fields[i - 1].length - (count - 5 + i) + j + 1;
                    } else {
                        field.nl = -1;
                    }
                    if (i < lanes - 1) {
                        field.nr = fields[i + 1].length + j + 1;
                    } else {
                        field.nr = -1;
                    }
                    if (dir === true) {
                        field.x =
                            startx +
                            Math.cos((((87 / (count - 5 + i - 1)) * j + degree) / 180) * Math.PI) * (radius + i * diff);
                        field.y =
                            starty +
                            Math.sin((((87 / (count - 5 + i - 1)) * j + degree) / 180) * Math.PI) * (radius + i * diff);
                    } else {
                        field.x =
                            startx +
                            Math.cos(
                                (((87 / (count - 5 + i - 1)) * (count - 5 + i - 1 - j) + degree) / 180) * Math.PI,
                            ) *
                                (radius + i * diff);
                        field.y =
                            starty +
                            Math.sin(
                                (((87 / (count - 5 + i - 1)) * (count - 5 + i - 1 - j) + degree) / 180) * Math.PI,
                            ) *
                                (radius + i * diff);
                    }
                }
                const field = fields[i][fields[i].length - 1] as Field; // tbd
                if (i < lanes - 1) {
                    field.nr = fields[i + 1].length + (count - 6 + i + 1) + 1;
                }
            }
        };

        const width = 19.4;
        const height = 15.5;
        const radius = 120;
        const diff = 15.5;

        const fields: Field[][] = [];
        for (let i = 0; i < 8; i++) {
            fields[i] = [];
        }

        straightPath(fields, 0, 18, 8, 234, 452, width, height, false, true);
        fields[6][17].nl = 18;

        curvedPath(fields, 18, 14, 6, 582, 331, 1.5, radius, diff, false);
        straightPath(fields, 32, 5, 6, 702, 236, height, width, true, false);
        curvedPath(fields, 37, 14, 6, 582, 218, 271.5, radius, diff, false);
        straightPath(fields, 51, 18, 6, 236, 20, width, height, false, false);
        curvedPath(fields, 69, 14, 6, 218, 218, 181.5, radius, diff, false); // dir = false added
        straightPath(fields, 83, 5, 6, 20, 236, height, width, true, true);
        curvedPath(fields, 88, 14, 6, 218, 332, 91.5, radius, diff, false); // dir = false added

        fields[5][101].nr = 19;

        straightPath(fields, 102, 18, 8, 234, 452, width, height, false, true);
        fields[6][35].nl = 120;

        curvedPath(fields, 120, 14, 6, 582, 331, 1.5, radius, diff, false);

        for (let i = 0; i < 8; i++) {
            fields[i][17].hurdle = true;
        }
        for (let i = 0; i < 6; i++) {
            fields[i][29 + i].hurdle = true;
            fields[i][41 + i * 2].hurdle = true;
            fields[i][58 + i * 2].hurdle = true;
            fields[i][70 + i * 3].hurdle = true;
            fields[i][82 + i * 4].hurdle = true;
        }
        for (let i = 6; i < 8; i++) {
            fields[i][18].hurdle = true;
            fields[i][35].hurdle = true;
        }

        return fields;
    };
}

class Field {
    public lane = 0;

    public pos = 0;

    public prog = 0;

    public nf = 0;

    public nl = 0;

    public nr = 0;

    public hurdle = false;

    public x = 0;

    public y = 0;
}

export { Board, Field, Game, Horse, Move, Player };

export default Turfmaster;
