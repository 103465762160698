/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { SpeedDial, SpeedDialAction, Backdrop } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useAccount } from '~/apis';
import { FullscreenContext, selectGameId, selectGameType, useSelector, useTranslation } from '~/app';
import { useAuthState } from '~/auth';
import { GameType } from '~/server/src/dtos';
import { cn } from '~/utils/styles';
import { ReactComponent as ImgExpand } from '../button-icons/button-expand.svg';
import { ReactComponent as ImgHome } from '../button-icons/button-home.svg';
import { ReactComponent as ImgLink } from '../button-icons/button-link.svg';
import { ReactComponent as ImgMenu } from '../button-icons/button-menu.svg';
import { ReactComponent as ImgWorldRankings } from '../button-icons/button-rankings.svg';
import { ReactComponent as ImgReport } from '../button-icons/button-report.svg';
import { ReactComponent as ImgRetract } from '../button-icons/button-retract.svg';
import { ReactComponent as ImgStatistics } from '../button-icons/button-statistics.svg';
import { ReactComponent as ImgTable } from '../button-icons/button-table.svg';

export type FloatingMenuItemKey = 'table' | 'worldRankings' | 'statistics' | 'close' | 'report' | 'link';

interface Props {
    onItemClicked: (key: FloatingMenuItemKey) => void;
    className?: string;
}

const imgStyle = { display: 'block', style: { width: '24px', height: '24px' } };

const FloatingMenu = ({ onItemClicked, className }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
    const { isFullscreen, toggleIsFullscreen } = useContext(FullscreenContext);

    const account = useAccount();
    const { isAuthorized } = useAuthState();
    const gameId = useSelector(selectGameId);
    const gameType = useSelector(selectGameType);

    return (
        <>
            <Backdrop
                open={isSpeedDialOpen}
                onClick={() => setIsSpeedDialOpen(false)}
                style={{ zIndex: 2, pointerEvents: 'all' }}
            />
            <SpeedDial
                ariaLabel="menu speeddial"
                className={className}
                icon={
                    <ImgMenu
                        className={cn('w-6 h-6 block transform-gpu transition-transform', {
                            'rotate-90': isSpeedDialOpen,
                        })}
                    />
                }
                open={isSpeedDialOpen}
                FabProps={{ style: { background: '#ffffff' } }}
                direction="down"
                onClick={() => setIsSpeedDialOpen((isSpeedDialOpen) => !isSpeedDialOpen)}>
                <SpeedDialAction
                    className="bg-white"
                    icon={<ImgStatistics {...imgStyle} />}
                    tooltipPlacement="right"
                    tooltipTitle={<div className="whitespace-nowrap">{t('game_menuStatistics')}</div>}
                    onClick={() => onItemClicked('statistics')}
                    tooltipOpen
                />
                <SpeedDialAction
                    className="bg-white"
                    icon={<ImgWorldRankings {...imgStyle} />}
                    tooltipPlacement="right"
                    tooltipTitle={<div className="whitespace-nowrap">{t('game_menuWorldRankings')}</div>}
                    onClick={() => onItemClicked('worldRankings')}
                    tooltipOpen
                />
                <SpeedDialAction
                    className="bg-white"
                    icon={<ImgTable {...imgStyle} />}
                    tooltipPlacement="right"
                    tooltipTitle={<div className="whitespace-nowrap">{t('game_menuTable')}</div>}
                    onClick={() => onItemClicked('table')}
                    tooltipOpen
                />
                <SpeedDialAction
                    className="bg-white"
                    icon={isFullscreen ? <ImgRetract {...imgStyle} /> : <ImgExpand {...imgStyle} />}
                    tooltipPlacement="right"
                    tooltipTitle={
                        <div className="whitespace-nowrap">
                            {isFullscreen ? t('game_menuFullscreenExit') : t('game_menuFullscreen')}
                        </div>
                    }
                    onClick={toggleIsFullscreen}
                    tooltipOpen
                />
                {gameId !== undefined && gameType !== GameType.Training && (
                    <SpeedDialAction
                        className="bg-white"
                        icon={<ImgLink {...imgStyle} />}
                        tooltipPlacement="right"
                        tooltipTitle={<div className="whitespace-nowrap">{t('game_menuLink')}</div>}
                        onClick={() => onItemClicked('link')}
                        tooltipOpen
                    />
                )}
                {account && isAuthorized && (
                    <SpeedDialAction
                        className="bg-white"
                        icon={<ImgReport {...imgStyle} />}
                        tooltipPlacement="right"
                        tooltipTitle={<div className="whitespace-nowrap">{t('game_menuReport')}</div>}
                        onClick={() => onItemClicked('report')}
                        tooltipOpen
                    />
                )}
                <SpeedDialAction
                    className="bg-white"
                    icon={<ImgHome {...imgStyle} />}
                    tooltipPlacement="right"
                    tooltipTitle={<div className="whitespace-nowrap">{t('game_menuHome')}</div>}
                    onClick={() => onItemClicked('close')}
                    tooltipOpen
                />
            </SpeedDial>
        </>
    );
};

export default FloatingMenu;
