/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { cloneDeep } from 'lodash';
import { EventChannel, Task } from 'redux-saga';
import { all, cancel, fork, put, PutEffect, select, take } from 'redux-saga/effects';
import { Coordinates, selectPlayerId } from '~/app';
import { Horse as StateHorse, setHorseField, setHorses, setHorsesField, setHurdles, setMoves } from '~/board';
import {
    setCardDice,
    setChatMessages,
    setHandiFailureHorseIndex,
    setHorseCards,
    setHorsesBonusCards,
    setHorsesCards,
    setLastCard,
    setLastCardHorseIndex,
    setLastTurnDicePlayerId,
    setLastTurnDices,
    setLastTurnDicesCur,
    setOwnHorseRemovedCards,
} from '~/overlay';
import { ChatMessage } from '~/server/src/dtos';
import { Controller } from '~/server/src/interfaces';
import Turfmaster, { Board, Field, Game, Horse, Move, Player } from '~/server/src/tmmodel';
import { actions as setupActions } from '~/setup';
import {
    setCurrentState,
    setHorsesBehindGoal,
    setHorsesDropped,
    setHorsesFinished,
    setHorsesHandi,
    setHorsesPoints,
    setHorsesRanks,
    setHorsesStarted,
    setPlayers,
    setRound,
    setTurnDicePlayerId,
    setTurnDices,
    setTurnDicesCur,
    setTurnOrder,
    setTurnPos,
    setTurnType,
} from './actions';
import calculateClientHorseColors from './calculateClientHorseColors';
import sagaWatchdog from './sagaWatchdog';

export const createMoveUpdateAction = (moves: Move[]): PutEffect =>
    put(
        setMoves(
            moves.map((move) => {
                return move.fields.map((field) => {
                    return { x: field.x, y: field.y };
                });
            }),
        ),
    );

export const createHorsesCardsUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesCards(
            horses.map((horse) => {
                return horse.cards;
            }),
        ),
    );

export const createHorsesRanksUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesRanks(
            horses.map(({ rank, totalrank }) => {
                return { rank, totalRank: totalrank };
            }),
        ),
    );

export const createHorsesHandiUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesHandi(
            horses.map((horse) => {
                return horse.handi;
            }),
        ),
    );

export const createHorsesPointsUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesPoints(
            horses.map((horse) => {
                return horse.points;
            }),
        ),
    );

export const createHorsesBonusCardsUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesBonusCards(
            horses.map((horse) => {
                return horse.bonuscards;
            }),
        ),
    );

export const createHorsesFinishedUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesFinished(
            horses.map((horse) => {
                return horse.finished;
            }),
        ),
    );

export const createHorsesDroppedUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesDropped(
            horses.map((horse) => {
                return horse.dropped;
            }),
        ),
    );

export const createHorsesStartedUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesStarted(
            horses.map((horse) => {
                if (horse && horse.field) return horse.field.pos > 0;
                else return horse.dropped || horse.finished;
            }),
        ),
    );

export const createHorsesBehindGoalUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesBehindGoal(
            horses.map((horse) => {
                if (horse && horse.field) return horse.field.pos > 18;
                else return horse.dropped || horse.finished;
            }),
        ),
    );

export const createPlayersUpdateAction = (players: Player[], horses: Horse[]): PutEffect =>
    put(
        setPlayers(
            players.map((player, index) => {
                return {
                    id: index,
                    userId: player.userid,
                    name: player.name,
                    type: player.type,
                    cardType: (horses.find((h) => h.playerid === index) || { cardType: Turfmaster.cardTypes.BRONZE })
                        .cardType,
                    totalRank: player.totalrank,
                    playerName: player.name,
                    country: player.country,
                };
            }),
        ),
    );

export const createInitialGameUpdateAction = (game: Game): PutEffect[] => [
    put(setupActions.setHorseAmount(game.horseamount)),
    put(setupActions.setGameName(game.name || '')),
    put(setRound(game.round)),
    put(setTurnPos(game.turnpos)),
    put(setTurnOrder(game.turnorder)),
    put(setTurnDices(game.turndices)),
    put(setTurnDicesCur(game.turndicescur)),
    put(setTurnType(game.turntype)),
    put(setTurnDicePlayerId(game.turndiceplayerid)),
    createPlayersUpdateAction(game.players, game.horses),
    createHorsesCardsUpdateAction(game.horses),
    createHorsesRanksUpdateAction(game.horses),
    createHorsesHandiUpdateAction(game.horses),
    createHorsesPointsUpdateAction(game.horses),
    createHorsesBonusCardsUpdateAction(game.horses),
    createHorsesFinishedUpdateAction(game.horses),
    createHorsesDroppedUpdateAction(game.horses),
    createHorsesStartedUpdateAction(game.horses),
    createHorsesBehindGoalUpdateAction(game.horses),
];

export const createHurdlesUpdateAction = (lanes: Field[][]): PutEffect => {
    // extract all hurdles in a map grouped by the field position
    const hurdles: Map<number, Coordinates[]> = new Map();
    lanes.forEach((lane) => {
        lane.forEach((field) => {
            if (field.hurdle) {
                if (field.prog === 120) return; // fix strange partial duplicated 120 hurdle
                if (!hurdles.get(field.prog)) hurdles.set(field.prog, []);
                (hurdles.get(field.prog) as Coordinates[]).push({ x: field.x, y: field.y });
            }
        });
    });
    return put(setHurdles(Array.from(hurdles.values())));
};

export const createHorsesUpdateAction = (horses: Horse[], clientHorseColors: number[]): PutEffect =>
    put(
        setHorses(
            horses.map<StateHorse>((horse, index) => {
                return {
                    id: horse.horseid,
                    index,
                    playerId: horse.playerid,
                    color: clientHorseColors[index],
                    jockeyName: horse.jockeyName,
                    realColor: horse.color,
                    name: horse.name,
                    nextTimeoutDateString: undefined, // TODO: map nextTimeoutDateString from controller
                };
            }),
        ),
    );

export const createHorsesFieldUpdateAction = (horses: Horse[]): PutEffect =>
    put(
        setHorsesField(
            horses.map(({ field }) => {
                return { x: field?.x ?? 0, y: field?.y ?? 0, pos: field?.pos ?? 0, prog: field?.prog ?? 0 };
            }),
        ),
    );

export const createHorseFieldUpdateAction = (index: number, horse: Horse, move?: Move): PutEffect =>
    put(
        setHorseField({
            index,
            value: {
                x: horse.field?.x ?? 0,
                y: horse.field?.y ?? 0,
                pos: horse.field?.pos ?? 0,
                prog: horse.field?.prog ?? 0,
                move:
                    move && move.fields
                        ? move.fields.map((field) => {
                              return { x: field.x, y: field.y };
                          })
                        : undefined,
            },
        }),
    );

export const createInitialBoardUpdateAction = (game: Game, board: Board, clientHorseColors: number[]): PutEffect[] => [
    createHurdlesUpdateAction(board.fields),
    createHorsesUpdateAction(game.horses, clientHorseColors),
    createHorsesFieldUpdateAction(game.horses),
];

export const createInitialOverlayUpdateAction = (chatMessages: ChatMessage[]): PutEffect[] => [
    put(setChatMessages(chatMessages)),
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sagaGameReceiver(controller: Controller, channel: EventChannel<any>) {
    const game = controller.getGame();
    const board = controller.getBoard();

    // only run game receiver if all needed dependencies are valid
    if (!controller || !channel || !game || !board) return;

    const ownPlayerId: number = yield select(selectPlayerId);
    const clientHorseColors = calculateClientHorseColors(game.horses, game.players, ownPlayerId);

    // initial game update
    yield all([
        ...createInitialGameUpdateAction(game),
        ...createInitialBoardUpdateAction(game, board, clientHorseColors),
    ]);

    // receive and handle ingame messages from controller
    while (true) {
        const watchdog: Task = yield fork(sagaWatchdog);

        // wait for next message of controller
        const { state, params } = yield take(channel);

        yield cancel(watchdog);

        const game = controller.getGame();
        const board = controller.getBoard();
        const chatMessages = controller.getChatMessages();

        const currentPlayerId =
            game.horses && game.turnorder && game.turnorder.length ? (controller.getPlayerId() as number) : -1;
        const currentHorseIndex = controller.getHorseId() as number;
        const playerId: number = yield select(selectPlayerId);

        // TODO: extract chat to other receiver
        const batchedEffects: PutEffect[] = [];
        if (state !== Turfmaster.stateMachine.NEWMESSAGE) {
            batchedEffects.push(put(setCurrentState(state)));
        }

        // react to controller message with e.g. firing the corresponding redux action
        switch (state) {
            case Turfmaster.stateMachine.JOINGAMEOPP: {
                console.log('controller => client:', 'JOINGAMEOPP', params, game, board);
                batchedEffects.push(createPlayersUpdateAction(game.players, game.horses));
                const clientHorseColors = calculateClientHorseColors(game.horses, game.players, ownPlayerId);
                batchedEffects.push(createHorsesUpdateAction(game.horses, clientHorseColors));
                break;
            }
            case Turfmaster.stateMachine.STARTGAME:
                {
                    console.log('controller => client:', 'STARTGAME', params, game, board);
                    const clientHorseColors = calculateClientHorseColors(game.horses, game.players, ownPlayerId);
                    batchedEffects.push(
                        ...createInitialGameUpdateAction(game),
                        ...createInitialBoardUpdateAction(game, board, clientHorseColors),
                    );
                    // set last dices to match the virtually last player without a value
                    batchedEffects.push(put(setLastTurnDicePlayerId(game.turndiceplayerid)));
                    batchedEffects.push(put(setLastTurnDices([-1, -1])));
                }
                break;
            case Turfmaster.stateMachine.STARTROUND:
                console.log('controller => client:', 'STARTROUND', params, game, board);
                batchedEffects.push(createHorsesFieldUpdateAction(game.horses));
                batchedEffects.push(createHorsesRanksUpdateAction(game.horses));
                batchedEffects.push(createPlayersUpdateAction(game.players, game.horses));
                batchedEffects.push(createHorsesHandiUpdateAction(game.horses));
                batchedEffects.push(createHorsesFinishedUpdateAction(game.horses));
                batchedEffects.push(createHorsesDroppedUpdateAction(game.horses));
                batchedEffects.push(createHorsesStartedUpdateAction(game.horses));
                batchedEffects.push(createHorsesBehindGoalUpdateAction(game.horses));
                batchedEffects.push(put(setTurnOrder(cloneDeep(game.turnorder))));
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                batchedEffects.push(put(setRound(game.round)));
                batchedEffects.push(put(setTurnType(game.turntype)));
                break;
            case Turfmaster.stateMachine.MERGECARDS:
                console.log('controller => client:', 'MERGECARDS', params, game, board);
                game.horses.forEach((horse, index) => {
                    if (horse.playerid === playerId)
                        batchedEffects.push(put(setHorseCards({ index: index, value: horse.cards })));
                });
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                if (playerId === currentPlayerId)
                    batchedEffects.push(put(setOwnHorseRemovedCards(params.removedCards || [])));
                else batchedEffects.push(put(setOwnHorseRemovedCards([])));
                break;
            case Turfmaster.stateMachine.MERGECARDSOPP:
                console.log('controller => client:', 'MERGECARDSOPP', params, game, board);
                batchedEffects.push(createHorsesBonusCardsUpdateAction(game.horses));
                game.horses.forEach((horse, index) => {
                    if (horse.playerid === playerId)
                        batchedEffects.push(put(setHorseCards({ index: index, value: horse.cards })));
                });
                if (playerId !== currentPlayerId) batchedEffects.push(put(setOwnHorseRemovedCards([])));
                break;
            case Turfmaster.stateMachine.STARTTURN:
                console.log('controller => client:', 'STARTTURN', params, game, board);
                batchedEffects.push(createHorsesRanksUpdateAction(game.horses));
                batchedEffects.push(createPlayersUpdateAction(game.players, game.horses));
                batchedEffects.push(createHorsesHandiUpdateAction(game.horses));
                batchedEffects.push(put(setTurnOrder(cloneDeep(game.turnorder))));
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                batchedEffects.push(put(setTurnType(game.turntype)));
                batchedEffects.push(put(setTurnDicePlayerId(game.turndiceplayerid)));
                break;
            case Turfmaster.stateMachine.ADDBONUSCARDSOPP:
                console.log('controller => client:', 'ADDBONUSCARDSOPP', params, game, board);
                const horseId = (params.horseid as number) || 0;
                const horse = game.horses[horseId];
                batchedEffects.push(createHorsesBonusCardsUpdateAction(game.horses));
                if (horse && horse.playerid === playerId) {
                    batchedEffects.push(put(setHorseCards({ index: horseId, value: horse.cards })));
                }
                break;
            case Turfmaster.stateMachine.SELECTCARDDICE:
                console.log('controller => client:', 'SELECTCARDDICE', params, game, board);
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                batchedEffects.push(put(setTurnOrder(cloneDeep(game.turnorder))));
                // if same card is clicked we are sending out SELECTCARDDICE with -1 and the server
                // does not respond with SELECTMOVE but with an empty SELECTCARDDICE
                if (game.turntype === Turfmaster.turntypes.CARDS && currentPlayerId === playerId)
                    batchedEffects.push(createMoveUpdateAction([]));
                break;
            case Turfmaster.stateMachine.SELECTMOVE:
                console.log('controller => client:', 'SELECTMOVE', params, game, board);
                batchedEffects.push(put(setCardDice(params.carddice)));
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                batchedEffects.push(put(setTurnOrder(cloneDeep(game.turnorder))));
                if (game.turntype === Turfmaster.turntypes.CARDS)
                    batchedEffects.push(put(setHandiFailureHorseIndex(-1)));
                if (currentPlayerId === playerId)
                    batchedEffects.push(createMoveUpdateAction((params.moves as Move[]) || []));
                break;
            case Turfmaster.stateMachine.SELECTMOVEOPP:
                console.log('controller => client:', 'SELECTMOVEOPP', params, game, board);
                batchedEffects.push(createHorsesDroppedUpdateAction(game.horses));
                batchedEffects.push(createHorsesFinishedUpdateAction(game.horses));
                batchedEffects.push(createHorsesStartedUpdateAction(game.horses));
                batchedEffects.push(createHorsesBehindGoalUpdateAction(game.horses));
                batchedEffects.push(
                    createHorseFieldUpdateAction(currentHorseIndex, game.horses[currentHorseIndex], params.move),
                );

                batchedEffects.push(put(setMoves([])));
                batchedEffects.push(put(setCardDice(params.carddice)));

                if (game.turntype === Turfmaster.turntypes.CARDS) {
                    batchedEffects.push(
                        put(setHorseCards({ index: currentHorseIndex, value: game.horses[currentHorseIndex].cards })),
                    );
                    batchedEffects.push(put(setLastCardHorseIndex(game.turnorder[game.turnpos])));
                    batchedEffects.push(put(setLastCard(params.carddice)));
                    batchedEffects.push(
                        put(
                            setHandiFailureHorseIndex(
                                params.carddice > 0 &&
                                    params.carddice >= Turfmaster.handi[game.horses[currentHorseIndex].handi] + 1
                                    ? currentHorseIndex
                                    : -1,
                            ),
                        ),
                    );
                }

                break;
            case Turfmaster.stateMachine.SELECTDICES:
                console.log('controller => client:', 'SELECTDICES', params, game, board);
                batchedEffects.push(put(setTurnDices(game.turndices)));
                break;
            case Turfmaster.stateMachine.SELECTDICESOPP:
                console.log('controller => client:', 'SELECTDICESOPP', params, game, board);
                batchedEffects.push(put(setTurnDicesCur(game.turndicescur)));
                batchedEffects.push(put(setLastTurnDicePlayerId(game.turndiceplayerid)));
                batchedEffects.push(put(setLastTurnDices(game.turndices)));
                batchedEffects.push(put(setLastTurnDicesCur(game.turndicescur)));

                // reset lastCard in dice round otherwise card from previous card round is shown on new card round
                batchedEffects.push(put(setLastCardHorseIndex(-1)));
                batchedEffects.push(put(setLastCard(-1)));
                break;
            case Turfmaster.stateMachine.ENDTURN:
                console.log('controller => client:', 'ENDTURN', params, game, board);
                batchedEffects.push(createHorsesRanksUpdateAction(game.horses));
                batchedEffects.push(createPlayersUpdateAction(game.players, game.horses));
                batchedEffects.push(createHorsesHandiUpdateAction(game.horses));
                batchedEffects.push(createHorsesDroppedUpdateAction(game.horses));
                batchedEffects.push(createHorsesFinishedUpdateAction(game.horses));
                batchedEffects.push(put(setHorsesPoints(game.horses.map((horse) => horse.points))));
                batchedEffects.push(put(setTurnOrder(game.turnorder)));
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                if (game.turntype === Turfmaster.turntypes.CARDS)
                    batchedEffects.push(put(setHandiFailureHorseIndex(-1)));
                break;
            case Turfmaster.stateMachine.ENDROUND:
                console.log('controller => client:', 'ENDROUND', params, game, board);
                batchedEffects.push(put(setTurnType(game.turntype)));
                batchedEffects.push(put(setTurnDicePlayerId(game.turndiceplayerid)));
                batchedEffects.push(put(setLastCardHorseIndex(-1)));
                batchedEffects.push(put(setLastCard(-1)));
                break;
            case Turfmaster.stateMachine.ENDGAME:
                console.log('controller => client:', 'ENDGAME', params, game, board);
                batchedEffects.push(createHorsesFieldUpdateAction(game.horses));
                batchedEffects.push(createHorsesRanksUpdateAction(game.horses));
                batchedEffects.push(createPlayersUpdateAction(game.players, game.horses));
                batchedEffects.push(createHorsesHandiUpdateAction(game.horses));
                batchedEffects.push(put(setTurnOrder(game.turnorder)));
                batchedEffects.push(put(setTurnPos(game.turnpos)));
                batchedEffects.push(put(setRound(game.round)));
                batchedEffects.push(put(setTurnType(game.turntype)));
                break;
            case Turfmaster.stateMachine.NEWMESSAGE:
                console.log('controller => client:', 'NEWMESSAGE', params, game, board);
                batchedEffects.push(put(setChatMessages(chatMessages)));
                break;
            default:
                console.log('controller => client:', '!!! UNKNOWN GAME STATE !!!', state, params, game, board);
                break;
        }

        yield all(batchedEffects);
    }
}

export default sagaGameReceiver;
