/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Popover, Transition } from '@headlessui/react';
import React, { useCallback, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import useLocalStorageState from 'use-local-storage-state';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    selectConnectionTimeout,
    selectCurrentHorseIndex,
    selectCurrentState,
    selectHorseCardDeck,
    selectIsCurrentOwnHorse,
    selectOwnHorse,
    selectPlayerId,
    selectRound,
    selectTurnType,
    useSelector,
    useTranslation,
} from '~/app';
import CheckIcon from '~/components/CheckIcon';
import { resetConnectionTimeout } from '~/game';
import { CardValue, sendAddBonusCards } from '~/overlay';
import HorseSelection, { HorseSelectionProps } from '~/overlay/horse-selection/HorseSelection';
import TurnControls, { TurnControlsProps } from '~/overlay/turn-controls/TurnControls';
import Turfmaster from '~/server/src/tmmodel';
import { useKeyPress } from '~/utils';
import { cn } from '~/utils/styles';
import Card from './Card';
import { ReactComponent as HideIcon } from './hide.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';

interface BarBottomProps extends TurnControlsProps, HorseSelectionProps {
    selectedOwnHorseIndex: number;
    selectedCardLocalIndex: number;
    onCardSelected: (index: number) => void;
}

const BarBottom = ({
    selectedOwnHorseIndex,
    selectedCardLocalIndex,
    onCardSelected,
    onCardMoveSelected,
    isDiceShuffling,
    onOwnHorseSelected,
}: BarBottomProps): JSX.Element => {
    const analytics = useFirebaseAnalytics();

    const { t } = useTranslation();

    const connectionTimeout = useSelector(selectConnectionTimeout);
    const [isConnectionTimeoutEnabled, setIsConnectionTimeoutEnabled] = useLocalStorageState(
        'isConnectionTimeoutEnabled',
        { defaultValue: true },
    );

    const isCurrentOwnHorse = useSelector(selectIsCurrentOwnHorse);
    const round = useSelector(selectRound);
    const currentState = useSelector(selectCurrentState);
    const turnType = useSelector(selectTurnType);
    const playerId = useSelector(selectPlayerId);
    const currentHorseIndex = useSelector(selectCurrentHorseIndex);
    const horse = useSelector((state) =>
        selectedOwnHorseIndex >= 0 ? selectOwnHorse(state, selectedOwnHorseIndex) : undefined,
    );
    const { cards, bonuscards } = useSelector((state) =>
        horse ? selectHorseCardDeck(state, horse.index) : { cards: undefined, bonuscards: undefined },
    );
    const horseColor = horse ? horse.color : 0;

    const currentCards = horse && cards && cards.length >= round + 1 && cards[round] ? cards[round] : [];

    const currentBonusCards =
        horse && bonuscards === -1 && cards && cards.length === Turfmaster.rounds + 1 && cards[Turfmaster.rounds]
            ? cards[Turfmaster.rounds]
            : [];

    const cardsCount = currentCards ? currentCards.length + (currentBonusCards.length > 0 ? 1 : 0) : 0;
    const offsetToCenterDeck = ((cardsCount - 1) * 50) / 2;
    const offsetAroundSelected = (index: number) => {
        if (selectedCardLocalIndex > 0) {
            if (index < selectedCardLocalIndex) return -20;
            else if (index > selectedCardLocalIndex) return 20;
        }
        return 0;
    };

    const dispatch = useDispatch();
    const onBonusCardClicked = () => {
        if (!horse) return;
        batch(() => {
            dispatch(sendAddBonusCards({ playerid: playerId, horseid: horse.index }));
            onCardSelected(-1);
        });

        analytics?.logEvent('use_bonuscards', { round, turnType, isCurrentOwnHorse });
    };

    // if game is in merge mode than mergable cards are kept in the previous cardStack so
    // we can detect if a horse has not merged yet by checking if the horse has multiple cardStacks
    const areSelectedOwnHorseCardsUnmerged = useMemo(() => {
        const cardsCount =
            cards?.reduce((prevCounter, curCards) => {
                if (curCards.length > 0) return prevCounter + 1;
                return prevCounter;
            }, 0) || 0;
        return cardsCount > 1;
    }, [cards]);

    const showConnectionTimeoutHint = isConnectionTimeoutEnabled && connectionTimeout;

    const cardsInvisible =
        areSelectedOwnHorseCardsUnmerged &&
        (currentState === Turfmaster.stateMachine.MERGECARDS || currentState === Turfmaster.stateMachine.MERGECARDSOPP);

    const cardsDisabled =
        showConnectionTimeoutHint ||
        turnType === Turfmaster.turntypes.DICES ||
        currentHorseIndex !== horse?.index ||
        [Turfmaster.stateMachine.MERGECARDS, Turfmaster.stateMachine.MERGECARDSOPP].includes(currentState);

    const bonusCardsDisabled =
        showConnectionTimeoutHint ||
        currentHorseIndex !== horse?.index ||
        [Turfmaster.stateMachine.MERGECARDS, Turfmaster.stateMachine.MERGECARDSOPP].includes(currentState);

    // arrow left: select left card
    const onLeftArrow = useCallback(() => {
        if (selectedCardLocalIndex > 0) onCardSelected(selectedCardLocalIndex - 1);
        else if (selectedCardLocalIndex < 0) onCardSelected(Math.floor(currentCards.length / 2));
    }, [currentCards.length, onCardSelected, selectedCardLocalIndex]);
    useKeyPress(37, onLeftArrow);

    // arrow right: select right card
    const onRightArrow = useCallback(() => {
        if (selectedCardLocalIndex >= 0) {
            if (selectedCardLocalIndex < currentCards.length - 1) onCardSelected(selectedCardLocalIndex + 1);
            else onCardSelected(currentCards.length - 1);
        } else onCardSelected(Math.floor(currentCards.length / 2));
    }, [currentCards.length, onCardSelected, selectedCardLocalIndex]);
    useKeyPress(39, onRightArrow);

    return (
        <div
            className={cn(
                'absolute bg-black/25 border-t border-x border-black/50 bottom-0 rounded-t-lg pointer-events-none left-0 right-0 px-4 h-[15vh] w-screen',
                'flex flex-col',
            )}>
            <div className="flex-grow grid grid-cols-[20%_50%_30%]">
                <div className="h-full flex flex-col pointer-events-auto">
                    <HorseSelection selectedOwnHorse={selectedOwnHorseIndex} onOwnHorseSelected={onOwnHorseSelected} />
                </div>

                <div className="relative h-full">
                    <div
                        className={cn(
                            'absolute top-0 bottom-0 left-0 right-0 flex flex-row justify-center items-end translate-y-[50%]',
                            'h-[15vh] scale-150',
                            {
                                'opacity-0': cardsInvisible,
                            },
                        )}>
                        {currentCards.length > 0 &&
                            currentCards.map((card, index) => {
                                const offsetCard = -50 * index;
                                return (
                                    <Card
                                        key={card + index}
                                        value={card as CardValue}
                                        className={cn(
                                            'max-w-[8vw]',
                                            'pointer-events-auto transition-all self-start cursor-pointer',
                                            {
                                                'cursor-not-allowed brightness-50': cardsDisabled,
                                            },
                                        )}
                                        color={horseColor}
                                        onClick={() => onCardSelected(index === selectedCardLocalIndex ? -1 : index)}
                                        style={{
                                            transform: `translate3d(${
                                                offsetCard + offsetToCenterDeck + offsetAroundSelected(index)
                                            }%, ${selectedCardLocalIndex === index ? -10 : 0}%, 0)`, // popup selected card
                                        }}
                                    />
                                );
                            })}

                        {currentBonusCards.length > 0 && (
                            <Card
                                key={'bonus'}
                                value={0}
                                className={cn(
                                    'max-w-[8vw]',
                                    'pointer-events-auto w-auto transition-all self-start cursor-pointer',
                                    {
                                        'cursor-not-allowed brightness-50': bonusCardsDisabled,
                                        'opacity-50': showConnectionTimeoutHint,
                                    },
                                )}
                                color={horseColor}
                                onClick={onBonusCardClicked}
                                style={{
                                    transform: `translate3d(${
                                        -50 * (cardsCount - 1) +
                                        offsetToCenterDeck +
                                        offsetAroundSelected(cardsCount - 1)
                                    }%, 0, 0)`,
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="w-full h-full flex flex-col pointer-events-auto">
                    <TurnControls onCardMoveSelected={onCardMoveSelected} isDiceShuffling={isDiceShuffling} />
                </div>
            </div>

            {/* Overlay / Backdrop component to block rest of UI behind popover */}
            <div
                aria-hidden={true}
                className={`${
                    showConnectionTimeoutHint ? 'opacity-30 fixed z-10 inset-0' : 'opacity-0'
                } bg-black pointer-events-auto`}
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(resetConnectionTimeout());
                }}
            />

            {/* (Temporary) reload button that suggest the user to reload the page if no message was received for a while */}
            <Popover className="bottom-4 right-4 absolute z-20 pointer-events-auto flex flex-row items-end space-x-3">
                {({ open }) => (
                    <>
                        <Transition
                            show={showConnectionTimeoutHint}
                            enter="transition duration-150 ease-out origin-right"
                            enterFrom="scale-x-50 translate-x-8 opacity-0 scale-y-50"
                            enterTo="scale-x-100 translate-x-0 opacity-100 scale-y-100"
                            leave="transition duration-150 ease-out origin-right"
                            leaveFrom="scale-x-100 translate-x-8 opacity-100 scale-y-100"
                            leaveTo="scale-x-50 translate-x-0 opacity-0 scale-y-50">
                            <Popover.Panel
                                className="flex flex-col items-start max-w-lg px-5 py-4 space-y-3 bg-white rounded-md shadow-md"
                                static>
                                <span>{t('game_connectionTimeoutText')}</span>

                                <div className="flex flex-row space-x-4">
                                    <button
                                        className="text-primary flex flex-row items-center px-3 py-2 space-x-2 text-sm bg-gray-200 rounded-md shadow-sm cursor-pointer"
                                        onClick={() => dispatch(resetConnectionTimeout())}>
                                        <CheckIcon className="w-3 h-3" />

                                        <span>{t('game_connectionTimeoutOkay')}</span>
                                    </button>

                                    <button
                                        className="text-primary flex flex-row items-center px-3 py-2 space-x-2 text-sm bg-gray-200 rounded-md shadow-sm cursor-pointer"
                                        onClick={() => setIsConnectionTimeoutEnabled(false)}>
                                        <HideIcon className="w-3 h-3" />

                                        <span>{t('game_connectionTimeoutHide')}</span>
                                    </button>
                                </div>
                            </Popover.Panel>
                        </Transition>

                        <button
                            className={cn(
                                'transition-all w-10 h-10 p-1.5 bg-white duration-300 rounded-full shadow-md',
                                {
                                    'rotate-180': showConnectionTimeoutHint,
                                },
                            )}
                            onClick={() => {
                                dispatch(resetConnectionTimeout());
                                if (typeof window !== 'undefined') {
                                    window.location.reload();
                                }
                            }}>
                            <RefreshIcon />
                        </button>
                    </>
                )}
            </Popover>
        </div>
    );
};

export default BarBottom;
