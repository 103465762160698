/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Modal, Slide } from '@mui/material';
import { sortBy, sortedUniq } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import {
    selectCurrentHorse,
    selectCurrentHorseCardDeck,
    selectCurrentState,
    selectOwnHorseRemovedCards,
    selectPlayerId,
    selectRound,
    useSelector,
} from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { useKeyPress } from '~/utils';
import { cn } from '~/utils/styles';
import { sendAddBonusCards, sendMergeCards } from '../actions';
import { CardValue } from '../bar-bottom/Card';
import { ReactComponent as ButtonSuccess } from '../button-icons/button-success.svg';
import CardRow from './CardRow';
import ViewSwitchButton from './ViewSwitchButton';

interface MergeViewProps {
    debugMode?: boolean;
}

const MergeView = ({ debugMode = false }: MergeViewProps): JSX.Element => {
    const dispatch = useDispatch();
    const currentState = useSelector(selectCurrentState);
    const round = useSelector(selectRound);
    const playerId = useSelector(selectPlayerId);
    const { cards, bonuscards } = useSelector(selectCurrentHorseCardDeck);
    const currentHorse = useSelector(selectCurrentHorse);
    const currentBonusCards = useMemo(
        () =>
            currentHorse &&
            bonuscards === -1 &&
            bonuscards &&
            cards?.length === Turfmaster.rounds + 1 &&
            cards[Turfmaster.rounds]
                ? cards[Turfmaster.rounds]
                : [],
        [currentHorse, cards, bonuscards],
    );

    const ownHorseRemovedCards = useSelector(selectOwnHorseRemovedCards);

    const currentPlayerId = currentHorse?.playerId ?? -1;

    const [added, setAdded] = useState<number[]>([]);
    const [removed, setRemoved] = useState<number[]>([]);

    const oldCards: CardValue[] = useMemo(
        () => (debugMode ? [8, 7, 7, 3, -11, 4, -10, 10, 6, 8] : cards?.[round - 1] ?? []),
        [cards, debugMode, round],
    );
    const newCards: CardValue[] = useMemo(
        () => (debugMode ? [8, 9, 9, 3, -12, 4, 10, -10, 6, 8] : cards?.[round] ?? []),
        [cards, debugMode, round],
    );

    const removedCards: CardValue[] = useMemo(
        () => (debugMode ? [-12, -12] : ownHorseRemovedCards),
        [debugMode, ownHorseRemovedCards],
    );

    const cardCount = newCards.length + Math.max(removedCards.length, currentBonusCards.length > 0 ? 1 : 0);
    const cardWidth = 80 / cardCount; // 80% of screen width => 10% padding left+right

    const showModal =
        debugMode || (currentState === Turfmaster.stateMachine.MERGECARDS && currentPlayerId === playerId);
    const [modalMinimized, setModalMinimized] = useState(false);

    const onAccept = useCallback(() => {
        if (added.length === removed.length)
            batch(() => {
                dispatch(
                    sendMergeCards({
                        playerid: playerId,
                        horseid: currentHorse?.index, // TODO: undefined check has to be forced
                        added,
                        removed,
                    }),
                );

                // modal is reusing ui so reset for merge in next round
                setAdded([]);
                setRemoved([]);
            });
    }, [added, removed, dispatch, playerId, currentHorse?.index]); // TODO: undefined check has to be forced

    const onBonusCardClicked = useCallback(() => {
        console.log('onBonusCardClicked');
        dispatch(sendAddBonusCards({ playerid: playerId, horseid: currentHorse?.index })); // TODO: undefined check has to be forced
    }, [currentHorse?.index, dispatch, playerId]); // TODO: undefined check has to be forced

    useKeyPress(
        13,
        useCallback(() => {
            if (showModal && !modalMinimized) onAccept();
        }, [modalMinimized, onAccept, showModal]),
    );

    return (
        <>
            <Modal className="pointer-events-auto" open={showModal && !modalMinimized} disablePortal>
                <Slide direction="up" in={showModal && !modalMinimized}>
                    <div className="focus:outline-none flex flex-col items-center justify-center w-full h-full">
                        <div className="h-1/2 flex flex-row items-end justify-center w-full mx-2 mb-4">
                            <CardRow
                                cards={oldCards}
                                isSelected={({ index }) => added.includes(index)}
                                onCardClicked={({ index }) => {
                                    setAdded((prev) =>
                                        prev.includes(index)
                                            ? prev.filter((i) => i !== index)
                                            : sortedUniq(sortBy([...prev, index])),
                                    );
                                }}
                                color={currentHorse?.color} // TODO: undefined check has to be forced
                                {...{ removedCards, cardWidth, onBonusCardClicked }}
                            />
                        </div>
                        <ButtonSuccess
                            className={cn('w-8 h-8 cursor-pointer transition-all transform-gpu active:scale-95', {
                                'opacity-30': added.length !== removed.length,
                            })}
                            onClick={onAccept}
                        />
                        <div className="h-1/2 flex flex-row items-start justify-center w-full mx-2 mt-4">
                            <CardRow
                                cards={newCards}
                                isSelected={({ index }) => !removed.includes(index)}
                                onCardClicked={({ index }) =>
                                    setRemoved((prev) =>
                                        prev.includes(index)
                                            ? prev.filter((i) => i !== index)
                                            : sortedUniq(sortBy([...prev, index])),
                                    )
                                }
                                bonusCardsEnabled={currentBonusCards.length > 0}
                                color={currentHorse?.color ?? 0} // // TODO: undefined check has to be forced
                                {...{ removedCards, cardWidth, onBonusCardClicked }}
                            />
                        </div>
                    </div>
                </Slide>
            </Modal>

            {showModal && <ViewSwitchButton isMinimized={modalMinimized} setMinimized={setModalMinimized} />}
        </>
    );
};

export default MergeView;
